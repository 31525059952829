import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SignUp from '../components/LandingPage/SignUp';

const styles = theme => ({
  loginBackground:{
    minHeight : '100vh',
    width : '100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundImage:'linear-gradient(to right,#3494E6fe,#EC6EADfe)'  
  },
  loginContainer:{
    width:'370px',
    height:'100%',
    margin:'0 auto',
  }
});


class SignUpPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className = {classes.loginBackground}>
        <div className={classes.loginContainer}>
          <SignUp />
        </div>
      </div>
      )
  }
}

export default withStyles(styles)(SignUpPage);