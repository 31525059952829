import React from 'react';

const VideoPlayer = (props) => {
  return (
    <div style={{ position: 'relative', height: '100%', overflow: 'hidden', maxWidth: '100%' }}>
      <iframe src={`https://www.youtube.com/embed/${props.videoId}?html5=1&amp;autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=0&amp;playsinline=1`}
        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '#000'}}
        frameBorder="0" title="Interleap"
        allowFullScreen="1"></iframe>
    </div>
  );
}

export default VideoPlayer;