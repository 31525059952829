import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EvaluationInfoPopOver from './EvaluationInfoPopOver';
import InfoPopOver from '../../InfoPopOver';
import Constants from './EvaluationConstants.json';
import EvaluationLabels from './EvaluationLabels.json'

const styles = theme => ({
    header: {
        fontWeight: '800',
        color: '#141111'
    },
    result: {
        marginBottom: '0.5vh'
    },
    table: {
        marginBottom: '4vh'
    },
    footer: {
        textAlign: 'center'
    }
    
});

function ViolationReport(props) {
    const { classes } = props;
    const parameters = Object.keys(props.violations);
    return (
        <div className={classes.root} data-testid="violationReport" >
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.header} align="left">
                            Evaluation Parameter
                        </TableCell>
                        <TableCell className={classes.header} align="center">
                            Threshold value
                        </TableCell>
                        <TableCell className={classes.header} align="center">
                            Number of Violations &nbsp;
                            <InfoPopOver title={EvaluationLabels.violationSeverityInfo.title} 
                                         description={EvaluationLabels.violationSeverityInfo.description}/>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parameters.map(parameter => (
                        <TableRow key={parameters.indexOf(parameter)}>
                            <TableCell component="th" scope="row" align="left">
                                {parameter}&nbsp;&nbsp;
                                <EvaluationInfoPopOver parameter={parameter} />
                            </TableCell>
                            <TableCell align="center">{Constants[parameter]}</TableCell>
                            <TableCell align="center">
                                {props.violations[parameter]}
                                &nbsp;&nbsp;
                                {0 === props.violations[parameter] ? (
                                    <img src="/static/images/tick.png" alt="passed" className={classes.result} />
                                ) : (
                                    <img src="/static/images/cross.png" alt="failed" className={classes.result} />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <h4 className={classes.footer}><i>Navigate to File Details to view specific violations</i></h4>
        </div>
    );
}

ViolationReport.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ViolationReport);
