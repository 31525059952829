import * as types from './ActionTypes';
import * as snackBarActions from './SnackbarActions'
import {handleErrors} from './ResponseErrorHandler'

export const login = (username, password) => {
  return ((dispatch) => {
    dispatch(requestLogin(types.STATE_PROCESSING))
    const requestBody = "username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password);
    return fetch("/server/login", {
      body: requestBody,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
      method: "post"
    }).then(handleErrors)
      .then(response => dispatch(requestLogin(types.STATE_SUCCESS, response.status)))
      .catch(error => dispatch(requestLogin(types.STATE_FAILURE, JSON.parse(error.message)))
      )
  })
}

export const signUp = (name, email, password) => 
  (dispatch => {
    const requestBody = {name, email, password}
    return fetch("/server/learn/users", {
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json;' },
      method: "post"
    }).then(response => response)
  })


export const requestLogin = (status , requestStatus) => ({
  type: types.REQUEST_LOGIN,
  state: status,
  requestStatus, 
})

export const requestChangePassword = (state) => ({
  type: types.REQUEST_CHANGE_PASSWORD,
  state: state
})

export const clearLoginState = () => ({
  type: types.LOGOUT,
  state: types.LOGGED_OUT,
})

function checkForErrorsResetPassword(response) {
  if (!response.ok) {
    throw response
  }
  return response;
}

function handleErrorsResetPassword(response,dispatch){
  if(!response.type || !response.status ){
    dispatch(snackBarActions.setErrorState("Your internet seems to be down.Check your internet connnection"))
    return;
  }
  const promise = response.text();    
  promise.then(text => {
    let errorMessage;
    try{
      let errorJson = JSON.parse(text)
      errorMessage = errorJson.message
      if(errorJson.errors && errorJson.errors.length)
        errorMessage = errorJson.errors[0].defaultMessage
    }catch(err){
        errorMessage = "Something seems to be wrong on our end. Please try again later."
    }
    dispatch(snackBarActions.setErrorState(errorMessage))
  })
  
}


export const checkLogin = () => {

  return ((dispatch) => {
    fetch('/server/loggedin')
      .then(response => response.json())
      .then(responseJSON => {
        if (responseJSON.loggedin === true) dispatch(requestLogin(types.STATE_SUCCESS))
        else  dispatch(requestLogin(types.LOGGED_OUT))
        dispatch(loggedinResponse(responseJSON.links))
      })
  }
  )
}

const loggedinResponse = links => ({
  type : types.CHECK_LOGGEDIN,
  payload : links
})

export const logout = () => {
  return ((dispatch) => {
    fetch('/server/logout')
      .then(handleErrors)
      .then(response => { dispatch(clearLoginState()) })
      .catch(() => {
        dispatch(snackBarActions.setErrorState("Error logging out. Please try again later."))
      })
  }
  )

}

export const changePassword = (password) => {
  return ((dispatch) => {
    dispatch(requestChangePassword(types.STATE_PROCESSING))
    return fetch("/server/users/me/password", {
      body: JSON.stringify({ password: password }),
      headers: { 'Content-Type': 'application/json;' },
      method: "put"
    }).then(response => handleErrors(response))
      .then(response => { dispatch(requestChangePassword(types.STATE_SUCCESS)) })
      .catch(error => { dispatch(requestChangePassword(types.STATE_FAILURE)) })
  })
}

export const clearChangePassword = () => requestChangePassword()



export const getUserInfo = () => {
  return (
    (dispatch) => {
      fetch('/server/users/me')
      .then(handleErrors)
      .then(response => response.text())
      .then(response => JSON.parse(response))
      .then(response => {
        dispatch ({
        type : types.GET_USER_INFO,
        payload : response,
      })})
      .catch(() => {
        dispatch(snackBarActions.setErrorState("Something went wrong. Try refreshing the page."))
      })
    }
  )
}

export const passwordResetRequest = (email) => {
  return (
    dispatch => {
      return fetch('/server/users/password-reset-request', {
        body : JSON.stringify({email: email}),
        headers: {'Content-Type': 'application/json;'},
        method: "post"
      })
      .then(checkForErrorsResetPassword)
      .then(res => res.text())
      .then(res => {
        dispatch(snackBarActions.setErrorState(res))
        return true;
      })
      .catch(err => {
        handleErrorsResetPassword(err,dispatch)
        return false;
      })
    }
  )
}

export const newUserLead = email => {
  return (
    dispatch => {
      return fetch('/server/inbound/user-lead', {
        body : JSON.stringify({email: email}),
        headers: {'Content-Type': 'application/json;'},
        method: "post"
      })
      .then(checkForErrorsResetPassword)
      .then(res => res.text())
      .then(res => {
        dispatch(snackBarActions.setErrorState("We have received your request."))
        return true;
      })
      .catch(err => {
        handleErrorsResetPassword(err,dispatch)
        return false;
      })
    }
  )
}

export const subscribeToCourseRequest = (courseId) => {
  return (
    dispatch => {
      return fetch(`/server/inbound/subscribe-request`, {
        body : JSON.stringify(courseId),
        headers: {'Content-Type': 'application/json;'},
        method: "post"
      })
      .then(checkForErrorsResetPassword)
      .then(res => res.text())
      .then(res => {
        dispatch(snackBarActions.setErrorState("We have received your request."))
        return true;
      })
      .catch(err => {
        handleErrorsResetPassword(err,dispatch)
        return false;
      })
    }
  )
}

export const query = (name, email, contactNo, organisationName, additionalMessage) => {
  return (
    dispatch => {
      return fetch('/server/inbound/query', {
        body : JSON.stringify({email: email, name: name, contactNo: contactNo, organisationName: organisationName, additionalMessage: additionalMessage}),
        headers: {'Content-Type': 'application/json;'},
        method: "post"
      })
      .then(checkForErrorsResetPassword)
      .then(res => res.text())
      .then(res => {
        dispatch(snackBarActions.setErrorState("We have received your request."))
        return true;
      })
      .catch(err => {
        handleErrorsResetPassword(err,dispatch)
        return false;
      })
    }
  )
}

export const resetPassword = (password,token) => {
  return (
    dispatch => {
      return fetch('/server/users/reset_password', {
        body : JSON.stringify({password:password,token:token}),
        headers: {'Content-Type': 'application/json;'},
        method: "post"
      })
      .then(checkForErrorsResetPassword)
      .then(res => res.text())
      .then(res => {
        dispatch(snackBarActions.setErrorState(res))
        return true;
      })
      .catch(err => {
        handleErrorsResetPassword(err,dispatch)
        return false;
      })
    }
  )
}