import thresholds from './EvaluationConstants.json';

const evaluationErrorMessages = {
    ClassCyclomaticComplexity: `Your class Cyclomatic Complexity is above ${thresholds['ClassCyclomaticComplexity']} for some classes`,
    ClassLength: `Your class Length is above ${thresholds['ClassLength']} for some classes`,
    MethodLength: `Your method Length is above ${thresholds['MethodLength']} for some methods`,
    MethodCyclomaticComplexity: `Your method Cyclomatic Complexity is above ${thresholds['MethodCyclomaticComplexity']} for some methods`,
    NPathComplexity: `Your method NPath Complexity is above ${thresholds['NPathComplexity']} for some methods`

}


export function getRemarks(violations) {
    const parameters = Object.keys(violations);

    return parameters.map(parameter => {
        const severityOfViolation = violations[parameter]
        return severityOfViolation > 0? evaluationErrorMessages[parameter] : ''
    }).filter(message => message !== "")

}
