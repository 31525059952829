import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Snowflake from '@material-ui/icons/AcUnit'
import LecturesCard from './LecturesCard';
import PlayArrow from '@material-ui/icons/PlayArrow'
import Reading from '@material-ui/icons/ImportContacts'
import School from '@material-ui/icons/School'

const styles = theme => ({
    lessons:{
        width:'100%',
        display:'flex',
        flexGrow:'1',
        '@media(max-width:720px)':{
            flexDirection:'column',
            padding:'2em'
        },
    },
    lessonNoContainer:{
        flexGrow:'1',
        width:'25%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        color:'#888',
        fontSize:'15px',
        '@media(max-width:720px)':{
            width:'100%',
            flexShrink:'3'
        },
    },
    lessonDetails:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        borderBottom:'#ddd solid 1px',
        padding:'4em 0em',
        width:'75%',
        '@media(max-width:720px)':{
            width:'100%',
            padding:'2em 0'
        },
    },
    lessonNo:{
        fontSize:'60px',
        fontWeight:'100',
    },
    lessonDesc:{
        color:'#555',
        marginTop:'1em'
    },
    lessonTitle:{
        fontSize:'34px',
        fontWeight:'400',
        color:'#333355',
    },
    snowflake:{
        color:'#4eb7f1',
        marginRight:'0.6em'
    },
    lectureCountContainer:{
        display:'flex',
        marginTop:'2em',
        color:'#777',
        '@media(max-width:570px)':{
            flexDirection:'column'
        },
    },
    lectureCount:{
        lineHeight:'1.8'
    },
    lessonDetailsContainer:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
    },
    lectureDetails:{
        marginLeft:'25%',
        '@media(max-width:720px)':{
            marginLeft:'2em'
        },
    },
    videoIcon:{
        color:'#32cd32'
    },
    readingIcon:{
        color:'#3792e4'
    },
    questionIcon:{
        color:'#333'
    },
    showButton:{
        lineHeight:'1.8',
        color:'#2a73cc',
        cursor:'pointer',
        marginLeft:'auto',
        padding:'0.5em 1em',
        borderRadius:'4px',
        border:'1px solid #2a73cc',
        '@media(max-width:570px)':{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:'0'
        },
    },
    lectureTypeCount:{
        display:'flex'
    }
})

class LessonCardOverview extends Component {
    state = {
        showLectures : false,
    }
    render() {
        const {classes} = this.props;
        let buttonText = this.state.showLectures ? "SEE LESS" : "SEE ALL"
        return (
            <div className={classes.lessonDetailsContainer}>
                <div className={classes.lessons}>
                        <div className={classes.lessonNoContainer}>
                            <p>Lesson</p>
                            <p className={classes.lessonNo}>{this.props.lessonNo}</p>
                        </div>
                        <div className={classes.lessonDetails}>
                            <p className={classes.lessonTitle}>{this.props.lessonName}</p>
                            <p className={classes.lessonDesc}>{this.props.lessonDesc}</p>
                            <div className={classes.lectureCountContainer}>
                                <div className={classes.lectureTypeCount}>
                                    <Snowflake className={classes.snowflake}/>
                                    {this.props.readings.length>0 && <p className={classes.lectureCount}>{this.props.readings.length} reading(s)</p>}
                                    {this.props.questions.length>0 && <p className={classes.lectureCount}>,&nbsp;{this.props.questions.length} question(s)</p>} 
                                    {this.props.videos.length>0 && <p className={classes.lectureCount}>,&nbsp;{this.props.videos.length} video(s)</p>}
                                </div>
                                <p className={classes.showButton} onClick={() => this.setState({showLectures:!this.state.showLectures})}>{buttonText}</p>
                            </div>
                        </div>
                </div>
                {this.state.showLectures &&
                <div className={classes.lectureDetails}>
                    {this.props.readings.length>0 && <LecturesCard lectures = {this.props.readings}  lectureType={"reading(s)"} icon={<Reading className={classes.readingIcon}/>}/>}
                    {this.props.questions.length>0 && <LecturesCard lectures = {this.props.questions} lectureType={"question(s)"} icon={<School className={classes.questionIcon}/>}/>}
                    {this.props.videos.length>0 && <LecturesCard lectures = {this.props.videos} lectureType={"video(s)"} icon={<PlayArrow className={classes.videoIcon}/>}/>}
                </div>}
            </div>
        );
    }
}

export default withStyles(styles)(LessonCardOverview);