export const REQUEST_LOGIN = 'REQUEST_LOGIN'

export const LOGOUT = 'LOGOUT'
export const CHECK_LOGGEDIN = 'CHECK_LOGGEDIN'
export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD'

export const ERROR = 'ERROR'

export const GET_USER_INFO = 'GET_USER_INFO'

export const GET_COURSES = 'GET_COURSES'
export const COURSE_DETAIL = 'COURSE_DETAIL'
export const COURSE_OVERVIEW = 'COURSE_OVERVIEW'
export const LESSON_DETAIL = 'LESSON_DETAIL'
export const GET_ANSWERS = 'GET_ANSWERS'

export const STATE_PROCESSING = 'PROCESSING'
export const STATE_SUCCESS = 'SUCCESS'
export const STATE_FAILURE = 'FAILURE'
export const STATE_UNSUBMITTED = 'UNSUBMITTED'
export const LOGGED_OUT = 'LOGGED_OUT'
export const HOMEPAGE_COURSES = 'HOMEPAGE_COURSES'

//Code Evaluation
export const LECTURE_CODE_QUALITY_EVALUATION = 'LECTURE_CODE_QUALITY_EVALUATION'
export const CODE_ANALYSIS = 'CODE_ANALYSIS'
