import React, { Component } from 'react';
import Header from '../Header';
import * as types from '../../actions/ActionTypes';
import * as actions from '../../actions/CourseActions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VideoPlayer from '../lectures/VideoPlayer';
import { Link } from 'react-router-dom';
import NavArrow from '@material-ui/icons/KeyboardArrowRight';
import DocumentType from '../lectures/DocumentType';
import QuestionType from '../lectures/QuestionType';
import LinearProgressBar from 'reactjs-progressbar-linear';
import TaskDone from '../TaskDone';
import FooterNav from '../FooterNav';
import Wrapper from '../Wrapper';
import * as snackBarActions from '../../actions/SnackbarActions';
import CourseNav from './CourseNav';
import './style.css';
import MCQ from '../lectures/MCQ';
import SimpleModal from '../courses/SimpleModal';
import { subscribeToCourseRequest } from '../../actions/UserActions';
import CodeEvaluationLecture from '../lectures/CodeEvaluationLecture';

const styles = theme => ({
    root: {
        width: '100%',
        flex: 1,
        height: '100vh',
        fontFamily: 'Arial'
    },
    videoPlayerDiv: {
        padding: '0 29.5vh',
        height: '61vh',
        '@media(min-width:1500px)': {
            height: '66vh'
        }
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: '100vw',
        '@media(max-width:1500px)': {
            padding: '0 20vw'
        }
    },
    navArrowIcon: {
        position: 'relative',
        top: '7px'
    },
    navButtons: {
        position: 'fixed',
        left: '0vw',
        bottom: '0vh'
    },
    courseNavOuterDiv: {
        position: 'absolute',
        top: '84pt',
        left: '0.3vh',
        backgroundColor: '#fff',
        borderRadius: '10px',
        height: '70%',
        '@media(min-width:1500px)': {
            height: '76%',
            width: '18vw'
        },
        '@media(max-width:1100px)': {
            height: '70%',
            width: '21vw'
        },
        width: '19vw',
        boxShadow: '0px 0px 6px 4px #ddd'
    },
    courseNavInnerDiv: {
        position: 'absolute',
        top: '0vh',
        backgroundColor: '#fff',
        borderRadius: '10px',
        height: '96.5%',
        '@media(min-width:1500px)': {
            height: '97.5%'
        },
        width: '100%',
        overflow: 'auto',
        margin: '1vh 0 1vh 0 '
    }
});

class LessonPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLectureIndex: 0,
            answerSaved: true,
            open: false
        };
    }

    componentDidMount() {
        this.props.fetchLesson(this.props.match.params.lessonId);
        this.props.fetchCourseDetails(this.props.match.params.courseId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.requestStatus === undefined) {
            this.props.fetchLesson(this.props.match.params.lessonId);
        }

        if (this.props.requestStatusFetchCourseDetails === undefined) {
            this.props.fetchCourseDetails(this.props.match.params.courseId);
        }

        if (this.props.requestStatus === types.STATE_SUCCESS && !this.props.requestStatusFetchAnswers) {
            this.props.fetchAnswersAsync(this.props.match.params.lessonId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.lessonId !== nextProps.match.params.lessonId) {
            this.setState({ currentLectureIndex: 0 });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    toggleAnswerSavedState = () => {
        this.setState({ answerSaved: !this.state.answerSaved });
    };

    setSnackBarMessage = msg => this.props.snackBar(msg);

    shiftLecture = increment => {
        const currentLectureIndex = this.state.currentLectureIndex + increment;
        if (currentLectureIndex >= 0 && currentLectureIndex <= this.props.lesson.lectures.length) this.setState({ currentLectureIndex });
    };

    checkLeftButtonVisibiity = () => this.state.currentLectureIndex > 0;

    checkRightButtonVisibiity = () => this.state.currentLectureIndex < this.props.lesson.lectures.length;

    contentIdentifier = () => this.props.lesson.lectures[this.state.currentLectureIndex].type;

    currentVideoId = () => this.props.lesson.lectures[this.state.currentLectureIndex].videoId;

    currentContent = () => this.props.lesson.lectures[this.state.currentLectureIndex].content;

    currentOptions = () => this.props.lesson.lectures[this.state.currentLectureIndex].options;

    findNextLessonId = () => {
        let index = this.props.courseDetails.lessonDetailDTO.findIndex(lesson => lesson.id === this.props.lesson.id);
        if (index < this.props.courseDetails.lessonDetailDTO.length - 1) return this.props.courseDetails.lessonDetailDTO[index + 1].id;
        return false;
    };

    findPreviousLessonId = () => {
        let index = this.props.courseDetails.lessonDetailDTO.findIndex(lesson => lesson.id === this.props.lesson.id);
        if (index > 0) return this.props.courseDetails.lessonDetailDTO[index - 1].id;
        return false;
    };

    findCurrentLessonIndex = () => {
        let index = this.props.courseDetails.lessonDetailDTO.findIndex(lesson => lesson.id === this.props.lesson.id);
        return index;
    };

    navigateToPrevious = () => {
        if (this.state.currentLectureIndex > 0) {
            this.shiftLecture(-1);
        } else {
            if (this.findPreviousLessonId() !== false) {
                this.props.history.push(`/courses/${this.props.match.params.courseId}/lessons/${this.findPreviousLessonId()}`);
            } else {
                this.props.history.push(`/courses/${this.props.match.params.courseId}`);
            }
        }
    };

    onMCQAnswered = isCorrect => {
        let message = isCorrect ? 'You answered the question correctly' : 'The answer is incorrect';
        this.setSnackBarMessage(message);
    };

    showSubscribeModal = () => {
        const nextLessonId = this.findNextLessonId();
        const lessonDetailDTO = this.props.courseDetails.lessonDetailDTO.filter(lesson => lesson.id === nextLessonId);
        return lessonDetailDTO.length > 0 && !lessonDetailDTO[0].accessible;
    };

    navigateToPreviousOnLessonEndPage = () => this.props.history.push(`/courses/${this.props.match.params.courseId}`);

    navigateToNext = () => {
        this.shiftLecture(+1);
    };

    navigateToNextOnLessonEndPage = () => {
        if (this.showSubscribeModal()) this.setState({ open: true });
        else if (this.findNextLessonId() !== false) {
            this.props.history.push(`/courses/${this.props.match.params.courseId}/lessons/${this.findNextLessonId()}`);
        } else {
            this.props.history.push('/');
        }
    };

    render() {
        const { classes } = this.props;
        let body;
        if (
            this.props.requestStatus === types.STATE_PROCESSING ||
            !this.props.requestStatus ||
            !this.props.requestStatusFetchCourseDetails ||
            this.props.requestStatusFetchCourseDetails === types.STATE_PROCESSING
        ) {
            body = <div> Loading Lesson... </div>;
        } else if (this.props.requestStatus === types.STATE_FAILURE || this.props.requestStatusFetchCourseDetails === types.STATE_FAILURE) {
            if (this.props.lesson === 404) this.props.history.push('/NOT_FOUND');
            body = <div> Error Loading Lesson, Please Try Later </div>;
        } else if (this.props.lesson.lectures.length === 0) {
            body = <div>Lecture Is Empty.</div>;
        } else {
            let content;
            let lectureName;
            let lectureType;

            if (this.state.currentLectureIndex >= 0 && this.state.currentLectureIndex < this.props.lesson.lectures.length) {
                lectureType = this.contentIdentifier();
                if (lectureType === 'VIDEO') content = <VideoPlayer videoId={this.currentVideoId()} />;
                else if (lectureType === 'DOCUMENT') content = <DocumentType content={this.currentContent()} />;
                else if (lectureType === 'CODE_EVALUATION')
                    content = (
                        <CodeEvaluationLecture
                            lectureId={this.props.lesson.lectures[this.state.currentLectureIndex].id}
                        />
                    );
                else if (lectureType === 'MCQ')
                    content = (
                        <MCQ
                            content={this.currentContent()}
                            answer={this.props.options[this.props.lesson.lectures[this.state.currentLectureIndex].id]}
                            options={this.currentOptions()}
                            lectureId={this.props.lesson.lectures[this.state.currentLectureIndex].id}
                            lessonId={this.props.lesson.id}
                            onMCQAnswered={this.onMCQAnswered}
                            type="MCQ"
                        />
                    );
                else
                    content = (
                        <QuestionType
                            content={this.currentContent()}
                            answer={this.props.answers[this.props.lesson.lectures[this.state.currentLectureIndex].id]}
                            lectureId={this.props.lesson.lectures[this.state.currentLectureIndex].id}
                            lessonId={this.props.lesson.id}
                            currentAnswerSavedState={this.state.answerSaved}
                            toggleAnswerSavedState={this.toggleAnswerSavedState}
                            type="Question"
                        />
                    );

                lectureName = `${this.props.lesson.lectures[this.state.currentLectureIndex].name} (${
                    this.state.currentLectureIndex + 1
                } of ${this.props.lesson.lectures.length})`;

                let sectionNavigator = !(lectureType === 'MCQ') ? (
                    <div className={classes.courseNavOuterDiv}>
                        <div className={[classes.courseNavInnerDiv, 'scrollbar'].join(' ')}>
                            <CourseNav
                                lessons={this.props.courseDetails.lessonDetailDTO}
                                lessonId={this.props.match.params.lessonId}
                                courseId={this.props.match.params.courseId}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                );
                body = (
                    <>
                        <Wrapper>
                            <Grid container spacing={0} direction="column">
                                <Grid item xs={12}>
                                    <Link to={{ pathname: '/' }} style={{ color: '#000000' }}>
                                        Home
                                    </Link>
                                    <NavArrow className={classes.navArrowIcon} />
                                    <Link to={{ pathname: `/courses/${this.props.match.params.courseId}` }} style={{ color: '#000000' }}>
                                        {this.props.courseDetails.name}
                                    </Link>
                                    <NavArrow className={classes.navArrowIcon} />
                                    {this.props.lesson.name}
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '2rem' }}>
                                        <b>{lectureName}</b>
                                    </p>
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: '4vh' }}>
                                    <div className={classes.flexContainer}>
                                        <LinearProgressBar
                                            progress={(this.state.currentLectureIndex + 1) / this.props.lesson.lectures.length}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.videoPlayerDiv}>{content}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.navButtons}>
                                        <FooterNav
                                            leftButtonName={'Back'}
                                            rightButtonName={'Continue'}
                                            lectureType={lectureType}
                                            currentAnswerSavedState={this.state.answerSaved}
                                            setSnackBarMessage={this.setSnackBarMessage}
                                            onClickLeft={this.navigateToPrevious}
                                            onClickRight={this.navigateToNext}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Wrapper>
                        {sectionNavigator}
                    </>
                );
            } else {
                body = (
                    <div>
                        <TaskDone
                            lessonLength={this.props.courseDetails.lessonDetailDTO.length}
                            currentLessonIndex={this.findCurrentLessonIndex() + 1}
                        />
                        <div className={classes.navButtons}>
                            <FooterNav
                                leftButtonName={'All Lessons'}
                                rightButtonName={'Continue'}
                                onClickLeft={this.navigateToPreviousOnLessonEndPage}
                                onClickRight={this.navigateToNextOnLessonEndPage}
                            />
                        </div>
                        <SimpleModal
                            open={this.state.open}
                            courseId={this.props.match.params.courseId}
                            handleClose={this.handleClose}
                            handleClick={() => this.props.subscribeToCourseRequest(this.props.match.params.courseId)}
                        />
                    </div>
                );
            }
        }
        return (
            <div className={classes.root}>
                <Header />
                {body}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    fetchLesson: id => dispatch(actions.fetchLessonAsync(id)),
    fetchCourseDetails: id => dispatch(actions.courseDetailsAsync(id)),
    fetchAnswersAsync: id => dispatch(actions.fetchAnswersAsync(id)),
    snackBar: msg => dispatch(snackBarActions.setErrorState(msg)),
    subscribeToCourseRequest: courseId => dispatch(subscribeToCourseRequest(courseId))
});

export const mapStateToProps = (state, ownProps) => {
    if (!state.courses.courseDetails[ownProps.match.params.courseId] || !state.courses.lessons[ownProps.match.params.lessonId]) return {};
    else {
        let lessonId = ownProps.match.params.lessonId;
        let answerRequestStatus = state.courses.answers[lessonId] ? state.courses.answers[lessonId].status : undefined;
        let answers = answerRequestStatus === types.STATE_SUCCESS ? state.courses.answers[lessonId].answers : [];
        let transformedAnswers = {};
        answers.forEach(element => {
            transformedAnswers[element.lectureId] = element.answer;
        });
        let transformedOptions = {};
        answers.forEach(element => {
            transformedOptions[element.lectureId] = element.optionSelected;
        });
        return {
            requestStatus: state.courses.lessons[ownProps.match.params.lessonId].status,
            lesson: state.courses.lessons[ownProps.match.params.lessonId].data,
            courseDetails: state.courses.courseDetails[ownProps.match.params.courseId].data,
            requestStatusFetchCourseDetails: state.courses.courseDetails[ownProps.match.params.courseId].status,
            requestStatusFetchAnswers: answerRequestStatus,
            answers: transformedAnswers,
            options: transformedOptions
        };
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LessonPage));
