import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles'
import HexagonalProgressBar from 'reactjs-progressbar-hexagonal';
import { Typography } from '@material-ui/core';
import Confetti from 'react-confetti'

const styles = theme => ({
    container : {
        backgroundColor : "#083456",
        minHeight : "93.9vh",
        display : "flex",
        flexBasis : "100vw",
        justifyContent : "center",
    },
    hexProgress : {
        marginTop : '22rem',
    },
    progressBar : {
        flex : '1',
        paddingLeft : "7vw",
        paddingRight: '7vw',
        display : "flex",
        justifyContent : "center",
    }
})

class TaskDone extends Component {
    constructor(props){
        super(props);
        this.state = {
            lessonProgress : 0,
        }
    }

    componentDidMount(){
        this.setState({lessonProgress : this.progress(this.props.currentLessonIndex-1)})
        setTimeout(() => this.setState({lessonProgress : this.progress(this.props.currentLessonIndex)}), 0)
    }

    completionMessage = () => this.progress(this.props.currentLessonIndex) === 100 ? 'Congratulations on finishing this course! You have gained a new skill today' : 'You have succesfully completed this lesson!'
    
    progress = (lessonIndex) => parseInt(lessonIndex/this.props.lessonLength * 100) < 0 ? 0 : parseInt(lessonIndex/this.props.lessonLength * 100)

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                {this.state.lessonProgress===100 && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={150} wind={0} recycle={false} gravity={0.1} friction={0.99}/>
                    </div>}
                <div className={classes.hexProgress}>
                    <div style={{textAlign : 'center'}}> 
                        <Typography color='secondary' variant='h3'>{this.completionMessage()}</Typography>
                    </div>
                    <div className={classes.progressBar}>
                        <HexagonalProgressBar progress={this.state.lessonProgress}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(TaskDone);