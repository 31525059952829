import React, { Component } from 'react';
import * as actions from '../../actions/CourseActions';
import { connect } from 'react-redux';
import * as types from '../../actions/ActionTypes'
import Typography from '@material-ui/core/Typography'
import { withStyles } from "@material-ui/core/styles";
import LessonCard from '../lessons/LessonCard';

const styles = theme => ({
  root: {
    paddingTop: '2vw',
    paddingLeft: '2vw',
    paddingBottom: '2vw',
  },

  container: {
    display: 'flex',
    flexBasis: '100vw',
    flexDirection: 'row',
    alignItems: "center",
    flexWrap: 'wrap',
    marginTop: '4vh'
  }
});

class CourseDetailContainer extends Component {

  componentDidMount() {
    this.props.fetchCourseDetails(this.props.match.params.id)
  }
  render() {
    const { classes } = this.props

    if (this.props.requestStatus === types.STATE_PROCESSING ||
      !this.props.requestStatus) {
      return (
        <div> Loading Course... </div>
      );
    } else if (this.props.requestStatus === types.STATE_FAILURE) {
      if (this.props.course === 404) this.props.history.push("/NOT_FOUND")
      return (
        <div> Error Loading Course, Please Try Later </div>
      );
    }
    const courseName = this.props.course.name;
    const lessons = this.props.course.lessonDetailDTO;
    const courseId = this.props.course.id
    let myComponent = <div>No lessons have been added to this course yet.</div>;
    if (lessons && lessons.length > 0)
      myComponent = lessons.map((lesson, index) => (<LessonCard key={lesson.name}
        lessonPath={this.lessonPath(courseId, lesson.id)}
        lessonName={index+1 + '. ' + lesson.name}
        courseId={courseId}
        lessonDescription={lesson.description}
        accessible={lesson.accessible} />))
    return (<div className={classes.root}>
      <Typography variant="h1" gutterBottom color='primary'>
        {courseName}
      </Typography>
      <div className={classes.container}>
        {myComponent}
      </div>
    </div>
    );
  }

  coursePath = (courseId) => { return "/courses/" + courseId }
  lessonPath = (courseId, lessonId) => `/courses/${courseId}/lessons/${lessonId}`
}

const mapDispatchToProps = dispatch => ({
  fetchCourseDetails: (courseId) => dispatch(actions.courseDetailsAsync(courseId)),
})

const mapStateToProps = (state, ownProps) => {
  if (!state.courses.courseDetails[ownProps.match.params.id]) return {}
  else return {
    requestStatus: state.courses.courseDetails[ownProps.match.params.id].status,
    course: state.courses.courseDetails[ownProps.match.params.id].data
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CourseDetailContainer));