import * as types from '../actions/ActionTypes'

const initialState = {
  courseDetails: {},
  lessons: {},
  answers: {}
}

const CourseReducer = (state = initialState, action) => {
  if (types.HOMEPAGE_COURSES === action.type) {
    return {
      ...state, courses: action.payload.myCourses , discoverCourses: {
        courses: action.payload.discoverCourses,
        status: action.status
      }
    }
  }

  if (types.COURSE_DETAIL === action.type) {
    let courseDetails = state.courseDetails;
    courseDetails = {
      ...courseDetails,
      [action.courseId]: {
        status: action.status,
        data: action.payload
      }
    }
    return { ...state, courseDetails: courseDetails }
  }

  if (types.LESSON_DETAIL === action.type) {
    let lessons = state.lessons;
    lessons = {
      ...lessons,
      [action.id]: {
        status: action.status,
        data: action.payload
      }
    }
    return { ...state, lessons: lessons }
  }

  if (types.GET_ANSWERS === action.type) {
    let answers = state.answers;
    answers = {
      ...answers,
      [action.id]: {
        status: action.status,
        answers: action.payload
      }
    }
    return { ...state, answers: answers }
  }

  if (types.COURSE_OVERVIEW === action.type) {
    return {
      ...state, courseOverview: {
        [action.courseName]: {
          overview: action.payload,
          status: action.status
        }
      }
    }
  }

  return state;
}

export default CourseReducer;