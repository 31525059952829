import * as types from './ActionTypes';

import { handleErrorResponseCodes, handleErrors } from './ResponseErrorHandler';

export const getUserCourses = () => {
    return dispatch => {
        fetch('/server/users/me/courses')
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON =>
                dispatch({
                    type: types.GET_COURSES,
                    payload: responseJSON
                })
            )
            .catch(error => {
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const courseDetailsAsync = courseId => {
    return dispatch => {
        dispatch(courseDetailRequest(courseId, types.STATE_PROCESSING));
        fetch('/server/learn/courses/' + courseId)
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON => dispatch(courseDetailRequest(courseId, types.STATE_SUCCESS, responseJSON)))
            .catch(error => {
                dispatch(courseDetailRequest(courseId, types.STATE_FAILURE, JSON.parse(error.message)));
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const fetchLessonAsync = lessonId => {
    return dispatch => {
        dispatch(lessonDetailRequest(lessonId, types.STATE_PROCESSING));
        fetch('/server/learn/lessons/' + lessonId)
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON => dispatch(lessonDetailRequest(lessonId, types.STATE_SUCCESS, responseJSON)))
            .catch(error => {
                dispatch(lessonDetailRequest(lessonId, types.STATE_FAILURE, JSON.parse(error.message)));
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const postAnswerAsync = (lectureid, answer, type, optionSelected) => {
    return dispatch => {
        let requestBody = { answer: answer, type: type, optionSelected: optionSelected };
        return fetch(`/server/lectures/${lectureid}/answer`, {
            body: JSON.stringify(requestBody),
            headers: { 'Content-Type': 'application/json;' },
            method: 'POST'
        })
            .then(handleErrors)
            .then(response => response.status)
            .catch(error => {
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const fetchAnswersAsync = lessonId => {
    return dispatch => {
        dispatch(answerRequest(lessonId, types.STATE_PROCESSING));
        return fetch(`/server/lessons/${lessonId}/answers`)
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON => dispatch(answerRequest(lessonId, types.STATE_SUCCESS, responseJSON)))
            .catch(error => {
                dispatch(answerRequest(lessonId, types.STATE_FAILURE))
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const courseOverview = courseName => {
    return dispatch => {
        fetch(`/server/courses/${courseName}/overview`)
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON => dispatch(courseOverviewRequest(courseName, types.STATE_SUCCESS, responseJSON)))
            .catch(error => {
                dispatch(courseOverviewRequest(courseName, types.STATE_FAILURE, null));
                handleErrorResponseCodes(error, dispatch);
            });
    };
};

export const homepageCourses = () => {
    return dispatch => {
        fetch(`/server/learn/courses`)
            .then(handleErrors)
            .then(response => response.text())
            .then(responseText => JSON.parse(responseText))
            .then(responseJSON => dispatch(coursesRequest(types.STATE_SUCCESS, responseJSON)))
            .catch(error => {
                dispatch(coursesRequest(types.STATE_FAILURE, JSON.parse(error.message)));
                handleErrorResponseCodes(JSON.parse(error.message), dispatch);
            });
    };
};

export const courseDetailRequest = (courseId, status, payload) => ({
    type: types.COURSE_DETAIL,
    courseId: courseId,
    status: status,
    payload: payload
});

const courseOverviewRequest = (courseName, status, payload) => ({
    type: types.COURSE_OVERVIEW,
    courseName,
    status,
    payload
});

export const lessonDetailRequest = (lessonId, status, payload) => ({
    type: types.LESSON_DETAIL,
    id: lessonId,
    status: status,
    payload: payload
});

export const answerRequest = (id, status, payload) => ({
    id,
    type: types.GET_ANSWERS,
    status,
    payload
});

const coursesRequest = (status, payload) => ({
    type: types.HOMEPAGE_COURSES,
    payload,
    status
});
