import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import EvaluationInfoPopOver from './EvaluationInfoPopOver';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'hidden'
    },
    Link: {
        color: '#0645AD'
    },
    header: {
        fontWeight: '800'
    },
    threshold: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1em'
    },
    value: {
        fontWeight: '800'
    },
    result: {
        marginBottom: '0.5vh'
    },
    info: {
        marginBottom: '0.5vh'
    }
});

function FileTable(props) {
    const { classes } = props;
    const files = props.files;
    const fileAnalysis = props.fileAnalysis;
    var classProperties = Object.keys(fileAnalysis[files[0]]);
    classProperties = classProperties.filter(item => item !== 'className' && item !== 'methodAnalysis');
    return (
        <div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.header}>File Name <i>(click on the file name to view method level violations)</i></TableCell>
                        {classProperties.map(classProperty => (
                            <TableCell align="center" className={classes.header}>
                                {classProperty} &nbsp;
                                <EvaluationInfoPopOver parameter={classProperty} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map(file => (
                        <TableRow key={files.indexOf(file)}>
                            <TableCell component="th" scope="row">
                                <Link
                                    component={RouterLink}
                                    to={{
                                        pathname: `/fileReport/${encodeURIComponent(
                                            JSON.stringify(file.substring(file.lastIndexOf('/') + 1))
                                        )}/${encodeURIComponent(JSON.stringify(fileAnalysis[file]['methodAnalysis']))}`
                                    }}
                                    className={classes.Link}
                                    target="_blank">
                                    {' '}
                                    {file}
                                </Link>
                            </TableCell>
                            {classProperties.map(classProperty => {
                                const value = fileAnalysis[file][classProperty]['value'];
                                const result = fileAnalysis[file][classProperty]['result'];
                                return (
                                    <TableCell align="center" className={classes.value}>
                                        {value}
                                        &nbsp;&nbsp;
                                        {result === 'passed' ? (
                                            <img src="/static/images/tick.png" alt="passed" className={classes.result} />
                                        ) : (
                                            <img src="/static/images/cross.png" alt="failed" className={classes.result} />
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

FileTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FileTable);
