import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EvaluationInfoPopOver from './EvaluationInfoPopOver';

const styles = theme => ({
    root: {
        marginTop: '10vh'
    },
    Link: {
        color: '#0645AD'
    },
    header: {
        fontWeight: '800'
    },
    threshold: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1em'
    },
    value: {
        fontWeight: '800'
    },
    result: {
        marginBottom: '0.5vh'
    },
    className: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    info: {
        marginBottom: '0.5vh'
    }
});

function MethodTable(props) {
    const { classes } = props;
    const methodAnalysis = JSON.parse(decodeURIComponent(props.match.params.evaluation));
    const className = JSON.parse(decodeURIComponent(props.match.params.class));
    const methodNames = Object.keys(methodAnalysis);

    if(methodNames.length === 0) {
        return(
            <div className={classes.root}>
            <center>
                <h4 className={classes.className}>{className}</h4>
                <h5><i>There are no methods to evaluate in this class</i></h5>
            </center>
            </div>
        )
    }

    var methodProperties = Object.keys(methodAnalysis[methodNames[0]]);
    return (
        <div className={classes.root}>
            <center>
                <h4 className={classes.className}>{className}</h4>
            </center>
            <br />
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.header}>Method Name</TableCell>
                        {methodProperties.map(methodProperty => (
                            <TableCell align="center" className={classes.header}>
                                {methodProperty} &nbsp;
                                <EvaluationInfoPopOver parameter={methodProperty} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {methodNames.map(methodName => (
                        <TableRow key={methodNames.indexOf(methodName)}>
                            <TableCell component="th" scope="row">
                                {methodName}
                            </TableCell>
                            {methodProperties.map(methodProperty => {
                                const value = methodAnalysis[methodName][methodProperty]['value'];
                                const result = methodAnalysis[methodName][methodProperty]['result'];
                                return (
                                    <TableCell align="center" className={classes.value}>
                                        {value}
                                        &nbsp;&nbsp;
                                        {result === 'passed' ? (
                                            <img src="/static/images/tick.png" alt="passed" className={classes.result} />
                                        ) : (
                                            <img src="/static/images/cross.png" alt="failed" className={classes.result} />
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

MethodTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MethodTable);
