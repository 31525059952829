import { combineReducers } from 'redux'
import UserReducer from './UserReducer'
import EventReducer from './EventReducer';
import CourseReducer from "./CourseReducer";
import LinkReducer from './LinkReducer';
import * as types from '../actions/ActionTypes'
import LectureCodeEvaluationReducer from './LectureCodeEvaluationReducer';
import CodeAnalysisReducer from './CodeAnalysisReducer';

const allReducers = combineReducers({users:UserReducer,
    events:EventReducer,
    courses:CourseReducer,
    links:LinkReducer,
    lectureCodeEvaluation: LectureCodeEvaluationReducer,
    codeAnalysis: CodeAnalysisReducer
})

const rootReducer = (state, action) => {

    switch (action.type) {
        case  types.LOGOUT:
            return allReducers(undefined,action)
        default:
            return allReducers(state, action)
    }
};

export default rootReducer;