import React from 'react';
import {withStyles} from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import {Link} from 'react-router-dom'

const style = () => ({
    ul:{
        display:'flex',
        listStyle:'none',
        margin:'0',
        alignItems:'center',
        flexWrap:'wrap',
        '@media(max-width:730px)':{
            '& li:not(:last-child)' :{
                display:'none'
            }
        }
    },
    nav:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        padding :'52px 8%',
        '@media(max-width:1000px)':{
            padding:'32px 0.5em'
        },
        '@media(max-width:730px)':{
            padding:'32px 0.5em'
        },
        color:'#fff',
        fontFamily:'Josefin Sans'
    },
    li:{
        marginRight:'2em',
        '@media(max-width:730px)':{
            marginRight:'0em',
        },
        fontSize:'16px'
    },
    logoImage:{
        height:'0.4in',
        width:'0.4in',
    },
    logoContainer:{
        display:'flex',
        alignItems:'flex-end',
        margin:'0',
    },
    companyName:{
        fontSize:'26px',
    },
    login:{
        backgroundColor:'#7bc97e',
        width:'12em',
        color:'#fff',
        borderRadius:'21px',
        padding:'0.3em 1.4em',
        textTransform: 'none',
        '&:hover':{
            backgroundColor:'#7bc97e'
        },
        '@media(max-width:730px)':{
            backgroundColor:'#00000000',
            textDecoration:'underline',
            fontSize:'20px',
            marginRight:'0',
            padding:'0'
        },
    },
    button:{
        color:'#fff',
        borderRadius:'21px',
        textTransform: 'none',
        '&:hover':{
            backgroundColor:'#7bc97e00'
        }
    },
    link:{
        textDecoration:'none',
        color:'#fff',
        '&:hover,&:focus,&:visited,&:active':{
            color:'#fff',
            textDecoration:'none',
        }
    }
})

const handleMouseOver = e => {
    e.target.classList.add("underline")
}

const handleMouseOut = e => {
    e.target.classList.remove("underline")
}

function LandingPageHeader(props){
    const {classes} = props;
    return (
            <nav className={classes.nav}>
                <Link to={"/"} className={classes.link}>
                    <div className={classes.logoContainer}>
                        <img src="/static/images/LogoWithoutText.png" alt="company-logo" className={classes.logoImage}/>
                        <span className={classes.companyName}>&nbsp;<b>Interleap</b></span>
                    </div> 
                </Link>
                <ul className={classes.ul}>
                    <li className={classes.li}><a href="/#consulting"><Button className={classes.button}><b onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Consulting</b></Button></a></li>
                    <li className={classes.li}><a href="/#clients"><Button className={classes.button}><b onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Clients</b></Button></a></li>
                    <li className={classes.li}><a href="/#bootcamps&trainings"><Button className={classes.button}><b onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Trainings</b></Button></a></li>
                    <li className={classes.li}><a href="/#courses"><Button className={classes.button}><b onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Courses</b></Button></a></li>
                    <li className={classes.li}><a href="#contact"><Button className={classes.button}><b onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Contact</b></Button></a></li>
                    <li className={classes.li}><Link to={"/login"}><Button className={classes.login}><b>Edtech Portal</b></Button></Link></li>
                </ul>
            </nav>
    )
}

export default withStyles(style)(LandingPageHeader)