import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormField from '../../components/FormField'
import { signUp } from '../../actions/UserActions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const styles = theme => ({
    paper: {
        borderRadius: '8px',
        display: "flex",
        width: '400px',
        flexDirection: "column",
        alignItems: "stretch",
        padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px ${theme
            .spacing.unit * 4}px`
    },
    submit: {
        backgroundColor: '#7bc97e',
        marginTop: '2em',
        borderRadius: '30px',
        fontWeight: '900',
        color: '#000',
        marginBottom:'1em',
        '&:hover': {
            backgroundColor: '#7bc97e'
        }
    },
    fields: {
        marginBottom: '0.2em'
    },
    buttonContainer: {
        textAlign: 'center'
    },
    header: {
        marginBottom: '0.4em',
        fontSize: '30px',
        textAlign: 'center',
        color: '#4296ba',
        fontWeight: '900'
    },
    message: {
        margin: '1vh 0 0 0',
        textAlign: 'center',
        padding: '2px 2px 2px 2px',
        borderRadius: '5px',
        boxSizing: 'border-box',
    },
    error: {
        border: 'solid thin #f99393',
        backgroundColor: "#fccaca",
        color: 'red'
    },
    success: {
        backgroundColor: "#cafcca",
        color: 'green',
        border: 'solid thin green',
    }
});


class SignUp extends Component {
    state = {
        name: '',
        username: '',
        password: '',
        message: '',
        success: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ success: undefined })
        this.props.signUp(this.state.name, this.state.username, this.state.password)
            .then(response => {
                if (response.status === 200)
                    this.setState({ success: true, message: "Account created successfully" })
                return response.text()
            })
            .then(response => JSON.parse(response))
            .then(response => {
                let message = ""
                if (response.status === 400)
                    message = response.errors[0].defaultMessage
                else if (response.status === 409)
                    this.setState({ success: true, message: "Email already exists" })
                else if (response.status > 400 && response.status < 500)
                    message = response.message
                else
                    message = "Something went wrong. Please try again later"
                if (!this.state.success) this.setState({ success: false, message })

            })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props
        const messageClass = this.state.success ? classes.success : classes.error
        return (
            <Paper className={classes.paper}>
                <h3 className={classes.header}>Sign Up</h3>
                <form onSubmit={this.handleSubmit}>
                    <div className={classes.fields}>
                        <FormField id="name" name="name" value={this.state.name}
                            autoComplete="name" autoFocus={true}
                            onChange={this.handleChange} label="Name" />
                    </div>
                    <div className={classes.fields}>
                        <FormField id="email" name="username" value={this.state.username}
                            autoComplete="email" autoFocus={false}
                            onChange={this.handleChange} label="Email Address" />
                    </div>
                    <div className={classes.fields}>

                        <FormField id="password" name="password" value={this.state.password}
                            autoComplete="current-password" autoFocus={false} type="password"
                            onChange={this.handleChange} label="Password" />
                    </div>
                    {this.state.success!==undefined && this.state.message && <div className={[classes.message, messageClass].join(' ')}>{this.state.message}</div>}
                    <div className={classes.buttonContainer}>
                        <Button fullWidth
                            variant="contained" color="primary" className={classes.submit} type='submit'>
                            Sign Up
                        </Button>
                        <div>Already have an acoount? <p><Link to="/login">Login</Link></p></div>
                    </div>
                    <div>
                    </div>
                </form>
            </Paper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    signUp: (name, username, password) => dispatch(signUp(name, username, password))
})

export default connect(() => ({}), mapDispatchToProps)(withStyles(styles)(SignUp));