import React from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";
import * as actions from "../../actions/UserActions";
import { connect } from "react-redux";
import ChangePasswordModal from './ChangePasswordModal'
import IconButton from '@material-ui/core/IconButton'


const styles = theme => ({
    root: {
    display: "flex"
    },
    paper: {
    marginRight: theme.spacing.unit * 2
    },
    userIcon:{
        height : '50px',
        width : '50px',
        color: 'white',
    },
});

class UserMenu extends React.Component {
  state = {
    open: false,
    changePasswordModalOpen: false
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    //If the click is on the anchor button, handle toggle will anyways be called.
    //But clickaway listener will call this function. So the toggle state becomes inaccurate.
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };


  handleLogout = (event) => {
        this.handleClose(event);
        this.props.logout();
  }
  changePassword = event => {
    this.handleClose(event);
    this.setState({changePasswordModalOpen: true})
  }

  onChangePasswordModalClose = event => {
    this.setState({changePasswordModalOpen: false})

  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <IconButton
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            <AccountCircle className = {classes.userIcon}/>
          </IconButton>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{'zIndex' : '2'}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem onClick={this.changePassword}>Change Password</MenuItem>
                      <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                    </MenuList>
                </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ChangePasswordModal open={this.state.changePasswordModalOpen} handleClose={this.onChangePasswordModalClose}/>
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired
};


export const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout())
})


export default connect (() => ({}), mapDispatchToProps)(withStyles(styles)(UserMenu));
