import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
    loader: {
        color: '#5eaa46'
    },
    reportLoader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '25vh'
    }
});
class LoadReport extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.reportLoader}>
                <CircularProgress className={classes.loader} size={60} />
                <p>
                    <b>Analysing Code</b>
                </p>
            </div>
        );
    }
}
export default withStyles(styles)(LoadReport);
