import React, { Component } from 'react';
import CourseCard from './CourseCard';

class CourseCards extends Component {
    render() {
        return (
            <>
                <CourseCard courseName={"Clean Coding"} gradient={"linear-gradient(#1FA2FF,#A6FFCB)"} live={true} courseDesc={"Learn about the best practices used in software development, testing and strategy patterns"} link="/explore/courses/clean-coding/overview"/>
                <CourseCard courseName={"Test Driven Development"} gradient={"linear-gradient(#CC95C0,#DBD4B4,#7AA1D2)"} live={true} courseDesc={"Learn how TDD helps you build software faster, with higher quality, complete test coverage, and more flexible design."} link="/explore/courses/test-driven-development/overview"/>
                <CourseCard courseName={"Docker in a Day"} gradient={"linear-gradient(#1D976C,#93F9B9)"} live={true} courseDesc={"Learn everything you need to know to build and deploy your own apps with Docker in a way that is simple to understand."} link="/explore/courses/docker-in-a-day/overview"/>
                <CourseCard courseName={"Mocks and Stubs"} gradient={"linear-gradient(#DBD4B4,#CC95C0)"} live={true} courseDesc={"Step by step guide to Mocks, Stubs, and Mockito. Includes hands-on exercises for each topic."} link="/explore/courses/mocks-and-stubs/overview"/>
            </>
        );
    }
}

export default CourseCards;