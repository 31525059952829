import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles'
import BootcampsSlideShow from './BootcampsSlideShow';
import CourseCards from './CourseCards';
import AboutUs from './AboutUs';
import Footer from './Footer';
import Clients from './Clients';
import Dash from './Dash';

const style = theme => ({
    root:{
        height:'100%',
        color:'#000',
        fontSize:'18px',
        fontFamily:'Josefin Sans',
        overflowX: 'hidden',
    },
    OutsideSVGBackgroundWrapper:{
        color:'#000',
        textAlign:'center',
        marginTop:'5em',
        '@media(max-width:900px)':{
            marginTop: '18em'
        },
        '@media(max-width:600px)':{
            marginTop: '25em'
        },
    },
    courses:{
        marginTop:'0em',
        marginBottom:'5em',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexWrap:'no-wrap',
        '@media(max-width:1200px)':{
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'space-between'
        },
        boxSizing:'border-box',
    },
    coursesDesc:{
        marginBottom:'10em',
        textAlign:'center',
        color:'#444',
    },
    courseTitle:{
        display:'inline-block'
    },
    upperSection:{
        padding:'7%',
    },
    
})

class Landing extends Component{
    componentDidMount(){
        const hash = this.props.location.hash.replace('#','')
        if(hash)
            document.getElementById(hash).scrollIntoView();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let element = document.getElementById("coursesContainer")
        this.handleScrollForElement(element, "scale")

        element = document.getElementById("clientsContainer")
        this.handleScrollForElement(element,"translateAndFadeIn")
    }

    handleScrollForElement = (element, animationClass) => {
        if (element.getBoundingClientRect().top < window.innerHeight || element.getBoundingClientRect().bottom < 0)
            element.classList.add(animationClass)
    }

    render(){
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Dash />
                <div className={classes.upperSection}>
                    <AboutUs />
                    <div id="clientsContainer">
                        <Clients />
                    </div>
                </div>
                <BootcampsSlideShow />
                <div className={classes.OutsideSVGBackgroundWrapper}>
                    <p className={["centerTitle","underline",classes.courseTitle].join(' ')} id="courses">COURSES</p>
                </div>
                <div className={[classes.courses].join(' ')} id = "coursesContainer">
                    <CourseCards />
                </div>
                <div className={classes.coursesDesc}>
                    All our courses are short and interactive. You spend all your time answering questions, writing code,<br/> and get bits and pieces of hints and theory in between.
                    <br />Courses involve real projects with real IDEs and technologies such as Git, just like you would use in an<br/> actual software development team.
                    <br/>They are designed by industry experts, who develop production grade software, and have created<br/> and conducted trainings in some of the largest multinationals.
                </div>
                <Footer />
            </div>
        )
    }
}

export default withStyles(style)(Landing)