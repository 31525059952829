import React from 'react';
import {withStyles} from '@material-ui/core/styles'
import Wrapper from '../../components/Wrapper'

const styles = theme => ({
    container:{
        display:'inline-block',
    },
    companyName:{
        fontSize:'30px',
    },
    CompanyNameContainer:{
        paddingBottom:'1.5em',
    },
    footer:{
        backgroundImage:'url(/static/images/Footer.svg)',
        width:'100vw',
        height:'350px',
        color:'#fff',
        display:'flex',
        alignItems:'center',
        '@media(min-width:1920px)':{
            backgroundImage:'linear-gradient(to right,#3494E6fe,#EC6EADfe)'  
        },
        backgroundRepeat:'no-repeat',
    },
})

function Footer(props){
    const {classes} = props
    return (
        <footer className={classes.footer} >
            <Wrapper>
                <div className={classes.container}>
                    <div className={classes.CompanyNameContainer}>
                        <span className={[classes.companyName,"underline"].join(' ')}>Interleap</span>
                    </div>
                    <div>
                        <p id='contact'>abhinav@interleap.co</p>
                    </div>
                </div>
            </Wrapper>
        </footer>
    )
}

export default withStyles(styles)(Footer);