import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: '5vh',
    },
    list: {
        listStyle: 'none',
        listStyleImage: 'url("/static/images/cross.png")'
    },
    msg: {
        fontWeight: '400',
        display: 'flex',
        justifyContent: 'left'
    },
    Text: {
        fontWeight: '800',
        marginLeft: '5.5vh'
    }
});

function Overview(props) {
    const { classes } = props;
    return (
        <div className={classes.root}>
            {props.msgs.length !== 0 ? (
                <Typography variant="h5" className={classes.Text}>
                    Remarks:
                </Typography>
            ) : (
                <div></div>
            )}
            &nbsp;
            {props.msgs.length !== 0 ? (
                props.msgs.map(msg => (
                    <ul className={classes.list} key={props.msgs.indexOf(msg)}>
                        <li>
                            <Typography className={classes.msg} data-testid="msg">
                                {msg}
                            </Typography>
                        </li>
                    </ul>
                ))
            ) : (
                <div></div>
            )}
        </div>
    );
}

Overview.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Overview);
