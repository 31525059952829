import * as types from '../actions/ActionTypes'

const initialState = {
  report: {}
}

export default (state = initialState, action) => {
  if(types.CODE_ANALYSIS !== action.type) return state

  const report =  {
    ...state.report,
    status: action.status,
    data: action.payload
  }
  return {
    ...state,
    report: report
  }
}
