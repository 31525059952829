import React, { Component } from 'react'
import * as types from '../actions/ActionTypes'
import Homepage from '../components/courses/Homepage'
import Landing from '../components/LandingPage/Landing'
import { connect } from 'react-redux'
import * as actions from '../actions/UserActions'
import queryString from 'query-string'

class HomepageWrapper extends Component{
    componentDidMount(){
        this.props.checkLogin()
    }

    render(){
        if(!this.props.loginState) return null;
        if(this.props.loginState===types.STATE_SUCCESS){
            const query = queryString.parse(this.props.location.search);
            if(query.continue){
                window.location.assign(`http://${query.continue}`)
                return null
            }
            return(<Homepage location={this.props.location} history={this.props.history}/>)
        }
        return(<Landing location={this.props.location}/>)
    }
}

export const mapStateToProps = state => ({loginState: state.users.loginState})

export const mapDispatchToProps = dispatch => ({
    checkLogin: () => dispatch(actions.checkLogin())
})

export default connect(mapStateToProps,mapDispatchToProps)(HomepageWrapper)
