import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
    container : {
        width : '100vw',
        height : '10vh',
        padding : '3vh',
        backgroundColor : 'rgba(150,150,150,0.4)',
        display : 'flex',
        zIndex : '999'
    },
    goBackButton : {
        position : 'absolute',
        left : '20vw',
        backgroundColor :  theme.palette.primary.main,
        color : '#fff',
        borderRadius : '20px',
        height : '4rem',
        width : '15rem',
        '&:hover' : {
            backgroundColor : 'rgba(8,92,186,1)',
        }
    },
    continueButton : {
        position : 'absolute',
        right : '20vw',
        backgroundColor : 'rgba(50,205,50,1)',
        borderRadius : '20px',
        height : '4rem',
        width : '15rem',
        color : '#fff',

        '&:hover' : {
            backgroundColor : 'rgba(50,255,50,1)',
        }
    }
})

class FooterNav extends Component {
    handleContinueClick = () => {
        if(this.props.lectureType === 'QUESTION' && this.props.currentAnswerSavedState!==true)
            this.props.setSnackBarMessage("Please type & save your response before proceeding to the next lecture.");    
        else
            this.props.onClickRight()
    }

    render() {
        let style = {};
        if(!this.props.currentAnswerSavedState && this.props.lectureType === 'QUESTION'){
            style.backgroundColor = '#ddd';
            style.color = "#000";
        }
        const {classes} = this.props;
        return (
            <div className = {classes.container}>
                    <Button variant="outlined" color = 'primary' className ={classes.goBackButton} onClick={this.props.onClickLeft}>
                        {this.props.leftButtonName}</Button>
                    <Button variant="outlined" className={classes.continueButton} onClick={this.handleContinueClick}>
                    {this.props.rightButtonName}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(FooterNav);