import * as types from '../actions/ActionTypes'

const initialState = {}

const LinkReducer = (state = initialState, action) => {
  if (types.CHECK_LOGGEDIN === action.type) {
    return { ...state, login: action.payload}
  }

  return state;
}

export default LinkReducer;