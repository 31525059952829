import * as types from './ActionTypes';

import { handleErrorResponseCodes, handleErrors } from './ResponseErrorHandler';

export const evaluateLectureCodeQuality = (lectureId, gitUrl, branchName, commitId) => {
    return dispatch => {
        dispatch(lectureCodeEvaluationRequest(lectureId, types.STATE_PROCESSING));
        return fetch(`/server/evaluation/${lectureId}/report`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                gitUrl: gitUrl,
                branchName: branchName,
                commitId: commitId
            })
        })
        .then(handleErrors)
        .then(response => response.text())
        .then(responseText => JSON.parse(responseText))
        .then(responseJSON => dispatch(lectureCodeEvaluationRequest(lectureId, types.STATE_SUCCESS, responseJSON)))
        .catch(error =>  {
            handleErrorResponseCodes(error, dispatch);
            dispatch(lectureCodeEvaluationRequest(lectureId, types.STATE_FAILURE));
        });
    }
};

export const lectureCodeEvaluationRequest = (lectureId, status, payload) => ({
    type: types.LECTURE_CODE_QUALITY_EVALUATION,
    lectureId: lectureId,
    status: status,
    payload: payload
});
