import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
function findTimeDifference(date) {
    var diff = Math.abs(new Date() - new Date(date));
    return Math.floor(diff / (1000 * 60 * 60 * 24));
}
const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 70,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        borderRadius: '7px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        marginBottom: '1em'
    },
    description: {
        textAlign: 'center',
        fontSize: '16px'
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        width: '12em',
        marginTop: '1em',
        marginBottom: '1em',
        borderRadius: '24px',
        color: '#fff ',
        '&:hover': {
            backgroundColor: 'rgba(8,92,186,1)'
        }
    },
    optional: {
        color: '#0f0'
    }
});

class SimpleModal extends React.Component {
    state = {
        showOptionalMessage: false,
        disable: false,
        timestamp: false,
        responseStatus: false
    };
    componentDidMount() {
        this.checkForOpenSubscriptionRequests();
    }

    componentDidUpdate() {
        this.checkForOpenSubscriptionRequests();
    }

    //TODO: The request should always return a JSON, not a string
    checkForOpenSubscriptionRequests() {
        if (!this.state.responseStatus && this.props.open) {
            fetch(`/server/users/subscription-request/${this.props.courseId}`)
                .then(response => {
                    this.setState({ responseStatus: true });
                    if (response.status === 200) {
                        this.setState({ disable: true });
                        return response.json();
                    } else {
                        throw response.status;
                    }
                })
                .then(timestamp => this.setState({ timestamp: findTimeDifference(timestamp) }))
                .catch(error => error);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Modal
                    aria-labelledby="Locked content"
                    aria-describedby="Locked content"
                    open={this.props.open}
                    onClose={this.props.handleClose}>
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="h1" id="modal-title" className={classes.title}>
                            This content is Locked
                        </Typography>
                        <Typography variant="subtitle1" id="simple-modal-description" className={classes.description}>
                            {this.state.timestamp === false
                                ? 'Please subscribe to this course to unclock content.'
                                : this.state.timestamp === 0
                                ? 'You have raised request today'
                                : this.state.timestamp === 1
                                ? 'You have raised request yesterday'
                                : 'You have raised request ' + this.state.timestamp + ' days ago'}
                        </Typography>
                        <Button
                            className={classes.button}
                            onClick={() => {
                                this.props.handleClick().then(status => {
                                    this.setState({ showOptionalMessage: status, responseStatus: false });
                                });
                            }}
                            disabled={this.state.disable}
                            style={this.state.disable ? { backgroundColor: '#808080' } : {}}>
                            Subscribe
                        </Button>
                        {this.state.showOptionalMessage && (
                            <Typography variant="subtitle6" id="simple-modal-description" className={classes.optional}>
                                We have received your request
                            </Typography>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}

SimpleModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleModal);
