import * as types from '../actions/ActionTypes'

const initialState = {
  evaluationReport: {}
}

const LectureCodeEvaluationReducer = (state = initialState, action) => {
    if (types.LECTURE_CODE_QUALITY_EVALUATION === action.type) {
        let evaluationReport = state.evaluationReport;
        evaluationReport = {
          ...evaluationReport,
          [action.lectureId]: {
            status: action.status,
            data: action.payload
          }
        }
        return { ...state, evaluationReport: evaluationReport }
    }
    return state;
   
}

export default LectureCodeEvaluationReducer;