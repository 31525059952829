import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {connect} from 'react-redux';
import * as actions from '../actions/SnackbarActions';

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  snackbarContainer:{
    fontSize:'13px'
  }
});

class ErrorSnackbar extends React.Component {

  state = {
    open: false,
    message: ''
  };


  componentDidUpdate(){
    if(this.props.error.state){
      this.setState({open : true});
      this.setState({message : this.props.error.message});
      this.props.resetErrorState();
    }
  }


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false, message: '' });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.snackbarContainer}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}


export const mapStateToProps = state => (
  {
    error : state.events.error
  }
)


export const mapDispatchToProps =  dispatch => (
  {
    resetErrorState : () => dispatch(actions.resetErrorState()),
  }
)

ErrorSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default connect (mapStateToProps,mapDispatchToProps)(withStyles(styles)(ErrorSnackbar));
