import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Lock from '@material-ui/icons/Lock'
import SimpleModal from "../courses/SimpleModal";
import {subscribeToCourseRequest} from '../../actions/UserActions'
import {connect} from 'react-redux'

const styles = (theme) => ({
  lessonContainer: {
    marginLeft: '11vw',
    position: 'relative'
  },
  card: {
    minWidth: '60vw',
    minHeight:'9vh',
    maxHeight: '16vh',
    borderBottom: 'dotted thin #ccc',
    transition: '1s',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      marginLeft: '4em'
    }
  },
  title: {
    color: "green"
  },
  description: {
    marginLeft: '2vw',
    color: '#0f1626',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    }
  }
})

class LessonCard extends Component {
  state = {
    open: false
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const noLessonName = "No lessons available in this lecture";
    const noLessonDescription = "We will notify you as soon as we add lectures to this course";
    const path = this.props.accessible ? this.props.lessonPath : {}
    const blurClass = this.props.accessible ? "" : "blur"
    return (
      <div className={classes.lessonContainer}>
        <div className={[classes.card, blurClass].join(' ')}>
          <Link to={path} className={classes.link}><CardContent>
            <h3 className={classes.title}>
              {this.props.lessonName ? this.props.lessonName : noLessonName}
            </h3>
            <Typography className={classes.description} component="p">
              {this.props.lessonDescription ? this.props.lessonDescription : noLessonDescription}
            </Typography>
          </CardContent></Link>
        </div>
        {!this.props.accessible && <div className={"blurOverlay"} onClick={()=> this.setState({open:true})}><Lock /></div>}
        <SimpleModal open={this.state.open} courseId = {this.props.courseId} handleClose={this.handleClose} handleClick={() => this.props.subscribeToCourseRequest(this.props.courseId)}/>
      </div>
    );
  }
}

LessonCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  subscribeToCourseRequest : (courseId) => dispatch(subscribeToCourseRequest(courseId))
})

export default connect(() => ({}), mapDispatchToProps)(withStyles(styles)(LessonCard));
