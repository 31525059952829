import React, { Component } from 'react'  
import { withStyles } from '@material-ui/core/styles';

const styles = theme =>( {
  body :{
    display : "flex",
    flexBasis: "100vw",
    margin : '0 auto',
    paddingLeft:'7vw',
    paddingRight: '7vw',
  },
  flexChildren : {
    flex:'1'
  }
})


class Body extends Component {
  render() { 
    const {classes} = this.props;
    return (
      <div className={classes.body}>
        <div className={classes.flexChildren}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Body);
