import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        fontSize:'35px',
        backgroundImage:'url(/static/images/Watermark.svg)',
    },
    container:{
        fontSize:'25px',
        justifyContent:'left',
        color:'#444',
        marginTop:'2em'
    },
    desc:{
        fontSize:'20px',
        color:'#888',
        marginBottom:'3em'
    }
  });

const strings = {
    header:"CONSULTING",
    content:[{
    title : "Value Focused",
    description: "We help you choose tools, techniques, and processes that help deliver software at lightning speed."
},{
    title : "Digital Transformation",
    description: "Our experts help your organisation automate their entire software development process, resulting in significant performance improvements."
},
{
    title : "Mentorshiip",
    description: "Not only do we create software for you, we upskill your entire team. The effects of upskilling are visible to the entire team."
},
{
    title : "Faster Feedback Cycle",
    description: "We use technologies and processes that help you build something and push it to customers in a matter of hours."
}]}

class AboutUs extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root} id="consulting">
                <p className="centerTitle">{strings.header}</p>
                <div className={classes.container}>
                    {strings.content.map((string, index) => <div key={index}><p>{string.title}</p>
                    <p className={classes.desc}>{string.description}</p></div>)}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AboutUs);