import * as types from './ActionTypes';
import { handleErrorResponseCodes, handleErrors } from './ResponseErrorHandler';

export const submitCodeForAnalysis = (gitUrl, branchName, commitId) => {
  return dispatch => {
    dispatch(codeAnalysisRequest(types.STATE_PROCESSING))
    return fetch(`/server/code-analysis`, {
      body: JSON.stringify({
        gitUrl: gitUrl,
        branchName: branchName,
        commitId: commitId
      }),
      headers: { 'Content-Type': 'application/json;' },
      method: 'POST'
    })
    .then(handleErrors)
    .then(response => response.text())
    .then(response => JSON.parse(response))
    .then(response => dispatch(codeAnalysisRequest(types.STATE_SUCCESS, response)))
    .catch(error => {
      handleErrorResponseCodes(error, dispatch);
      dispatch(codeAnalysisRequest(types.STATE_FAILURE));
    })
  }
}

const codeAnalysisRequest = (status, payload) => ({
  type: types.CODE_ANALYSIS,
  status: status,
  payload: payload
});

