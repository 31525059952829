import React, { Component } from 'react';
import Header from '../Header';
import UserCourses from './UserCourses';
import Body from '../Wrapper';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  section: {
    padding: '2em'
  }
})

class Homepage extends Component {
  render() {
    const { classes } = this.props
    return (
      <>
        <Header />
        <div className={classes.section}>
          <Body>
            <UserCourses history={this.props.history}/>
          </Body>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Homepage);
