import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FileTable from './FileTable';
import ViolationReport from './ViolationReport';
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    Tabs: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Overview: {
        marginTop: '15vh'
    },
    FileDetails: {
        marginTop: '10vh'
    }
});

class Details extends React.Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        const evaluation = JSON.parse(decodeURIComponent(this.props.match.params.evaluation));
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange} className={classes.Tabs}>
                        <Tab label="Overview" data-testid="overview" />
                        <Tab label="File Details" data-testid="fileDetails" />
                    </Tabs>
                </AppBar>
                {value === 0 && (
                    <div className={classes.Overview}>
                        <ViolationReport violations={evaluation.violations} />
                    </div>
                )}
                {value === 1 && (
                    <div className={classes.FileDetails}>
                        <FileTable files={Object.keys(evaluation.fileAnalysis)} fileAnalysis={evaluation.fileAnalysis} />
                    </div>
                )}
            </div>
        );
    }
}

Details.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Details);
