import React from 'react';
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
    clientDesc:{
        padding:'0 2em',
        '@media(max-width:1500px)':{
            padding:'0',
            marginTop:'3em'
        },
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        color:'#666'    
    },
    clientContainer:{
        display:'flex',
        padding:'8em 0',
        '@media(max-width:1500px)':{
            flexDirection:'column'
        },
    },
})

const strings = {
    header : "OUR CLIENTS",
    content : ["We have clients from all domains, and of all sizes. Some of the leaders in the industry are using our courses to upskill their employees. Here are some of them."]
}

function Clients(props){
    const {classes} = props
    return (
        <div className={classes.clientContainer} id="clients">        
            <div className={classes.clientDesc}>
                <p className="centerTitle">{strings.header}</p>
                <p>{strings.content[0]}</p>
            </div>
            <img src='/static/images/Clients.png' alt="All clients" width='100%'/>
        </div>
    )
}

export default withStyles(styles)(Clients)