import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import {Link} from 'react-router-dom'

const styles = theme => ({
    root :{
        color:'#000',
        borderRadius:'6px',
        width:'360px',
        '@media(max-width:500px)':{
            width:'300px'
        },
        boxSizing:'border-box',
        border:'1px solid #00000000',
        '@media(max-width:1200px)':{
            margin:'2em 0'
        },
        '&:hover':{
            border:'1px solid #4285f466'
        },
        margin:'0 1em',
        position:'relative',
        overflow:'hidden',   
        '&:hover > .overlay':{
            animation: 'cover 0.3s ease-out',
            'animation-iteration-count': '1',
            animationFillMode: 'forwards',
        }
    },
    courseImage:{
        height:'230px',
        borderRadius:'6px 6px 0 0',
        textAlign:'center',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color:'#fff',
        fontSize:'25px'
    },
    courseDesc:{
        padding:'1em',
        paddingTop:'3em',
        color:'#555',
        fontSize:'15px',
        height:'200px',
        textAlign:'center',
        backgroundColor: '#fffffe',
        borderRadius:'0 0 8px 8px',
        boxShadow:'inset 0px 0px 2px #ccc',
    },
    overlayText:{
        color:'#666'
    },
    overlayButton:{
        color:'#666',
        borderRadius:'30px',
        padding:'0.3em 1.8em',
        fontSize:'24px',
        '&:hover':{
            backgroundColor:'#444',
            color:'#7bc97eff',
        }
    }
})

class CourseCard extends Component {
    render() {
        const {classes} = this.props;
        const overlayText = this.props.live ? <Link to={`${this.props.link}`}><Button className={classes.overlayButton} variant="outlined" >EXPLORE</Button></Link> : 
        <h1 className={classes.overlayText}>COMING SOON</h1>
        return (
                <div className={classes.root}>
                        <div className={classes.courseImage} style={{backgroundImage:`${this.props.gradient}`}}>{this.props.courseName}</div>
                        <div className={classes.courseDesc}>{this.props.courseDesc}</div>
                        <div className={"overlay"}>
                            {overlayText}
                        </div>
                </div>
        );
    }
}

export default withStyles(styles)(CourseCard);