import React, { Component } from 'react';
import Header from '../Header';
import Body from '../Wrapper';
import CourseDetailContainer from './CourseDetailContainer'

class CoursePage extends Component {
  render() {
    return (
      <div>
        <Header />
        <Body>
          <CourseDetailContainer match={this.props.match} history={this.props.history}/>
        </Body>
      </div>
    );
  }
}

export default CoursePage;
