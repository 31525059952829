import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import CoursePage from '../components/courses/CoursePage';
import LessonPage from '../components/lessons/LessonPage';
import * as types from '../actions/ActionTypes';
import { connect } from 'react-redux';
import NotFound404Page from '../pages/NotFound404Page';
import ForgotPassword from '../pages/ForgotPassword';
import SignUpPage from '../pages/SignUpPage'
import SendPasswordResetLink from '../pages/SendPasswordResetLink';
import HomepageWrapper from '../pages/HomepageWrapper';
import CourseOverview from '../components/courses/CourseOverview';
import Details from '../components/lectures/Evaluation/Details';
import MethodTable from '../components/lectures/Evaluation/MethodTable';
import ReactGA from 'react-ga';
import CodeAnalysisPage from '../components/code-analysis/CodeAnalysisPage';

if (window.location.hostname === 'interleap.co') {
    ReactGA.initialize('UA-162560508-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
    />
);
const referrer = window.location.search;

const Routes = props => {
    return (
        <Router>
            <div style={{ height: '100%' }}>
                <Switch>
                    <Route
                        path="/login"
                        render={props => {
                            props.referrer = referrer;
                            return <LoginPage {...props} />;
                        }}
                    />
                    <Route path="/" exact component={HomepageWrapper} />
                    <PrivateRoute path="/courses/:id" isAuthenticated={props.isAuthenticated} exact component={CoursePage} />
                    <PrivateRoute
                        path="/courses/:courseId/lessons/:lessonId"
                        isAuthenticated={props.isAuthenticated}
                        exact
                        component={LessonPage}
                    />
                    <Route path="/report/:evaluation" exact component={Details} />
                    <Route path="/fileReport/:class/:evaluation" exact component={MethodTable}/>
                    <Route path="/code-analysis" exact component={CodeAnalysisPage}/>

                    <Route path="/reset_password" exact component={ForgotPassword} />
                    <Route path="/password-reset-request" exact component={SendPasswordResetLink} />
                    <Route path="/signup" exact component={SignUpPage} />
                    <Route path="/explore/courses/:courseName/overview" exact component={CourseOverview} />
                    <Route exact component={NotFound404Page} />
                </Switch>
            </div>
        </Router>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.users.loginState === types.STATE_SUCCESS
});

export default connect(mapStateToProps, {})(Routes);
