import React from 'react';
import LoadReport from '../lectures/Evaluation/LoadReport';
import EvaluationSummary from '../lectures/Evaluation/EvaluationSummary';
import CodeAnalysisInput from './CodeAnalysisInput';
import * as actionTypes from '../../actions/ActionTypes';

const CodeAnalysis = (props) => {

    if(props.requestStatus === actionTypes.STATE_PROCESSING)
      return( 
        <LoadReport data-testid="loader" />
      )
  
  if(props.requestStatus === actionTypes.STATE_SUCCESS)
      return (
        <EvaluationSummary evaluation={props.formattedReport} 
        history={props.history} 
        data-testid="report" />
      )

  if(props.requestStatus === actionTypes.STATE_FAILURE || 
      props.requestStatus === actionTypes.STATE_UNSUBMITTED)
      return (
            <CodeAnalysisInput submitCodeForAnalysis={props.submitCodeForAnalysis} />
      );

}

export default CodeAnalysis