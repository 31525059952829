import * as snackBarActions from './SnackbarActions';
import * as UserActions from './UserActions'

export function handleErrors(response) {
  if (!response.ok) {
    throw new APIError(response.status || "", response);
  }
  return response;
}
  
export function handleErrorResponseCodes(error, dispatch){
  if(!(error instanceof APIError)) {
    dispatch(snackBarActions.setErrorState('Something went wrong. Check your internet connectivity and retry.'));
    return
  }
  
  const status = error.response.status
  if(status === 401){
    dispatch(UserActions.logout());
    return;
  } 
  if(!status) {
    dispatch(snackBarActions.setErrorState('Check your internet connectivity and retry.'));
    return;
  }

  const contentType = error.response.headers.get('content-type');
  if (!contentType || contentType.indexOf('application/json') === -1) {
    dispatch(snackBarActions.setErrorState(createErrorMessage(status, "")))
  } else {
    error.response.json().then(responseJson => {
      dispatch(snackBarActions.setErrorState(createErrorMessage(status, responseJson.message)))
    });
  }
}

const createErrorMessage = (status, message) => {
  const defaultErrorMessagesByCode = {
    403: "This resource is either not available or you don't have permission to access it.",
    404: "Resource not found.",
    400: "Bad request. Please check that the input parameters are in the right format.",
    409: "There was a conflict. Does the resource already exist?"
  };

  const defaultErrorMessage = "Something went wrong. Try refreshing the page.";

  return message || defaultErrorMessagesByCode[status] || defaultErrorMessage;
}


export class APIError extends Error {
  constructor(message, response){
    super(message)
    this.response = response
  }
}