import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    container : {
        overflow : 'auto',
        height : '100%',
    },
    header : {
        height : '5vh',
        backgroundColor : theme.palette.primary.main,
        opacity:'0.8',        
        padding :'1.6vh',
        borderRadius:'4px 4px 0 0'
    },
    dot : {
        height: '1em',
        width: '1em',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight : '0.2em'
      }
})

class LectureHeader extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.header}>
                <span className={classes.dot}></span>
                <span className={classes.dot}></span>
                <span className={classes.dot}></span>
                <span className={classes.dot}></span>
            </div>
        );
    }
}

export default withStyles(styles)(LectureHeader);