import React, { Component } from 'react'  
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import UserMenu from './users/UserMenu';
import BasicUserInfo from './users/BasicUserInfo';
import { Link } from "react-router-dom";
import Wrapper from './Wrapper';

const styles = theme =>( {
  container :{
    display : "flex",
    alignItems : "center",
    flexBasis: "100vw",
  },
  box1 :{
    flex : "16",
  },
  box2 :{
    flex : "0.7",
    marginTop : '-0.6rem'
  },
  box3 :{
    flex : "1",
    marginTop : '-2.6rem',
    height : '5rem',
  },
  logo : {
    height : '0.5in',
    marginTop : '-0.5rem'
    },
    button: {
      margin: theme.spacing.unit,
      display : "flex",
      flexDirection : 'row',
      color : '#ffffff',
    },
    appBar :{
      height : '45pt',
      position : 'static',
    },
});


class Header extends Component {
    
    render() { 
      const { classes } = this.props;
        return (
            <div>
            <AppBar className = {classes.appBar}>
              <Toolbar>
                <Wrapper>
                <div className = {classes.container}>
                    <div className = {classes.box1}>
                      <Link to='/' ><img className = {classes.logo} src='/static/images/interleaplogo.png' alt='Company-Logo'/></Link>
                    </div>
                    <div className = {classes.box2}>
                      <BasicUserInfo className = {classes.userMenu}/>
                    </div>
                    <div className = {classes.box3}> 
                      <UserMenu />
                    </div>
                </div>
                </Wrapper>
              </Toolbar>
            </AppBar>
          </div>
      );
    }
}
 
export default withStyles(styles)(Header);
