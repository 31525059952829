import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import DetailCourseDescModal from './DetailCourseDescModal';
import { Button } from '@material-ui/core';

const styles = theme => ({
    root: {
        borderRadius: '4px',
        boxShadow: '0 0 8px #ccc',
        width: '400px',
        height: '230px',
        overflow: 'hidden'
    },
    title: {
        margin: '0',
        padding: '0',
        marginBottom: '1em',
        color: '#333'
    },
    body: {
        color: '#666'
    },
    sideMarker: {
        backgroundImage: 'linear-gradient(to right,#1D976C,#93F9B9)',
        borderRadius: '4px 4px 0 0',
        width: '100%',
        height: '8px'
    },
    content: {
        padding: '10% 5%',
    },
    htmlButton: {
        border: 'none',
        backgroundColor: '#0000',
        color: 'blue'
    },
    descText: {
        margin: '0',
        color: '#666',
    },
    buttonLink: {
        textTransform: "none",
        padding: '0',
        textAlign: 'left'
    }
})

class DiscoverCourseCard extends Component {
    state = {
        open: false,
    };

    allowedStringLength = 100

    componentDidMount() {
        if (this.props.desc.length >= this.allowedStringLength)
            document.getElementById(`readMoreButton${this.props.id}`).addEventListener('click', this.handleOpen)
    }

    componentWillUnmount() {
        if (this.props.desc.length >= this.allowedStringLength)
            document.getElementById(`readMoreButton${this.props.id}`).removeEventListener('click', this.handleOpen)
    }

    handleOpen = e => {
        e.stopPropagation()
        this.setState({ open: true })
    }

    handleClose = () => this.setState({ open: false });

    render() {
        const { classes } = this.props
        const readMore = this.props.desc.length < this.allowedStringLength ? "" : `...<button class=${classes.htmlButton} id=readMoreButton${this.props.id}>read more</button>`
        return (
            <>
                <Button onClick={() => this.props.history.push(`${this.props.path}`)} className={classes.buttonLink}>
                    <div className={classes.root}>
                        <div className={classes.sideMarker}>&nbsp;</div>
                        <div className={classes.content}>
                            <h4 className={classes.title}>{this.props.name}</h4>
                            <div>
                                <p className={classes.descText} dangerouslySetInnerHTML={{ __html: `${this.props.desc.substring(0, this.allowedStringLength)} ${readMore}` }} />
                            </div>
                        </div>
                    </div>
                </Button>
                <DetailCourseDescModal open={this.state.open} handleClose={this.handleClose} handleOpen={this.handleOpen} title={this.props.name} desc={this.props.desc} />
            </>
        );
    }
}

export default withStyles(styles)(DiscoverCourseCard);