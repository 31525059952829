import React from 'react';
import PropTypes from 'prop-types';
import Constant from './EvaluationConstants.json';
import Info from './EvaluationConstantsInfo.json';
import InfoPopOver from '../../InfoPopOver';

const EvaluationInfoPopOver = ({parameter}) => {
    return (
        <InfoPopOver title={parameter}
                     description={Info[parameter]}
                     footer={`Threshold value - ${Constant[parameter]}`}/>
    )
}

EvaluationInfoPopOver.propTypes = {
    parameter: PropTypes.string.isRequired
};

export default EvaluationInfoPopOver;
