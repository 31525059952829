import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    lecture:{
        padding:'1em 0',
        display:'flex'
    },
    lecturesContainer:{
        color:'#666',
        padding:'1em 0',
        borderBottom:'#ddd solid 1px',        
    },
    lectureHeader:{
        lineHeight:'1.9',
        marginLeft:'0.5em'
    }
})

class LecturesCard extends Component {
    render() {
        const {classes} =  this.props
        let lectures=[]
        if(this.props.lectures.length)
            lectures = this.props.lectures.map((lecture, index) => <div key={index} className={classes.lecture}>{lecture.name}</div>)
        return (
            <div className={classes.lecturesContainer}>
                <div className={classes.lecture}>
                    {this.props.icon}<p className={classes.lectureHeader}><b>{lectures.length}&nbsp;&nbsp;{this.props.lectureType}</b></p>
                </div>
                {lectures}
            </div>
        );
    }
}

export default withStyles(styles)(LecturesCard);