import React, { Component } from 'react';
import * as actions from '../../actions/CourseActions';
import { connect } from 'react-redux';
import CourseCard from './CourseCard';
import DiscoverSection from './DiscoverSection';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        alignItems: 'left',
        rowGap: '1.5em'
    }
});
class UserCourses extends Component {
    componentDidMount() {
        this.props.getUserCourses();
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                {this.props.userCourses && this.props.userCourses.length > 0 && (
                    <div>
                        <h3>My Courses</h3>
                        <div className={classes.wrapper}>
                            {this.props.userCourses.map(course => (
                                <CourseCard
                                    key={course.name}
                                    coursePath={this.coursePath(course.id)}
                                    id={course.id}
                                    courseName={course.name}
                                    history={this.props.history}
                                    courseDescription={course.description}
                                />
                            ))}
                        </div>
                    </div>
                )}
                <DiscoverSection history={this.props.history} />
            </>
        );
    }

    coursePath = courseId => {
        return '/courses/' + courseId;
    };
}

const mapDispatchToProps = dispatch => ({
    getUserCourses: () => dispatch(actions.homepageCourses())
});

const mapStateToProps = state => ({
    userCourses: state.courses.courses
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserCourses));
