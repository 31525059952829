import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/UserActions';
import * as snackBarActions from '../../actions/SnackbarActions';
import FormField from '../FormField';
import * as actionTypes from '../../actions/ActionTypes'
import Done from '@material-ui/icons/Done'

function getModalStyle() {

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

class ChangePasswordModal extends Component {

  constructor(props){
    super(props)
    this.state = {
      password: '',
      confirmpassword: '',
      submitted: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidUpdate() {
    if(this.props.changePasswordStatus===actionTypes.STATE_SUCCESS){
      this.setState({password: "", confirmpassword:""})
      if(this.props.open) this.props.handleClose()
      this.props.setErrorState("Password successfully changed.")
      this.props.clearChangePasswordStatus()
    }

    if(this.props.changePasswordStatus===actionTypes.STATE_FAILURE){
        this.props.setErrorState("Error changing password. Please try again later.")
        this.props.clearChangePasswordStatus()
      }
  }

  onClose = () => {
    this.setState({password :'', confirmpassword : ''})
    this.props.handleClose();
  }



  confirmedPasswordMatchesNewPassword = () => this.state.confirmpassword === this.state.password && this.state.password

  render() {
    const { classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.onClose}>
        <div style={getModalStyle()} className={classes.paper}>
        <div style={{width : "90%"}}>
          <FormField id="password" name="password" value={this.state.password}
            autoComplete="password" autoFocus={true} type="password"
            onChange={this.handleChange} label="New Password" />
            </div>
          <div style={{display : 'flex', alignItems : 'flex-end'}}>
          <div style={{width : "90%"}}>
            <FormField id="confirmpassword" name="confirmpassword"
              style={{flex:'1'}}
              value={this.state.confirmpassword}
              autoComplete="confirmpassword" autoFocus={false} type="password"
              onChange={this.handleChange} label="Confirm Password" />
              </div>
              {this.confirmedPasswordMatchesNewPassword() && <Done style={{flex:'1', marginLeft:"10px", color: "green"}}/>}
             
          </div>
          <br/>
          <div style={{display:'flex'}}>
            <p style={{color : 'red', verticalAlign : "super"}}>*</p>
            <p style={{whiteSpace:"pre-wrap"}}>  The password length should be between 4-30 characters</p>
          </div>

          <Button fullWidth
            variant="contained" color="primary" className={classes.submit} disabled={!this.confirmedPasswordMatchesNewPassword()}
            onClick={() => this.props.sendChangePasswordRequest(this.state.password)}>
            Change Password
            </Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  changePasswordStatus: state.users.changePasswordStatus
})
const mapDispatchToProps = dispatch => ({
  sendChangePasswordRequest: (password) =>  dispatch(actions.changePassword(password)),
  clearChangePasswordStatus: () =>  dispatch(actions.clearChangePassword()),
  setErrorState: (message) => dispatch(snackBarActions.setErrorState(message))
})

ChangePasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect (mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePasswordModal))

