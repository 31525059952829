import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Option from './Option';
import * as snackBarActions from '../../actions/SnackbarActions';
import { connect } from 'react-redux';

const styles = theme => ({
    paper: {
        height: '100%'
    },
    container: {
        overflow: 'auto',
        height: '100%'
    },
    content: {
        margin: '5vh',
        whiteSpace: 'pre-wrap',
        fontSize: '18px'
    },
    OptionContainer: {
        margin: '3em 0 3em 0'
    },
    buttonContainer: {
        width: '100%',
        textAlign: 'center'
    }
});

class MCQ extends Component {
    state = {
        revealAnswer: false,
        correctAnswer: '',
        isAnswerExist: true
    };
    getCorrectAnswer = lectureId => {
        fetch(`/server/lectures/${lectureId}/answer`)
            .then(response => response.json())
            .then(response => {
                this.setState({ correctAnswer: response });
            })
            .catch(error => {
                this.props.snackBar('Correction needed in the question.Unable to show correct answer');
            });
    };
    componentDidMount() {
        if (this.props.answer) {
            this.getCorrectAnswer(this.props.lectureId);
        }
    }
    handleSubmit = () => {
        this.setState({ revealAnswer: true });
        this.getCorrectAnswer(this.props.lectureId);
    };
    componentDidUpdate(prevProps) {
        if (this.props.answer !== prevProps.answer) {
            this.getCorrectAnswer(this.props.lectureId);
        }
    }
    componentWillReceiveProps = nextProps => {
        if (this.props.lectureId !== nextProps.lectureId) {
            this.setState({ revealAnswer: false, correctAnswer: '' });
        }
    };

    render() {
        const { classes } = this.props;
        const colors = ['#9788ef', '#97d058', '#f8c433', '#e86667'];
        return (
            <Paper className={classes.paper}>
                <div className={[classes.container, 'scrollbar'].join(' ')}>
                    <div className={[classes.content].join(' ')}>
                        <b>
                            <div className={'code'} dangerouslySetInnerHTML={{ __html: this.props.content }} />
                        </b>
                        <div className={classes.OptionContainer}>
                            {this.props.options.map((option, index) => (
                                <Option
                                    key={option.id}
                                    answer={this.props.answer}
                                    correctAnswer={this.state.correctAnswer}
                                    revealAnswer={this.state.revealAnswer}
                                    option={option.option}
                                    id={option.id}
                                    handleSubmit={this.handleSubmit}
                                    color={colors[index % 4]}
                                    alphabet={65 + index}
                                    correct={option.correct}
                                    onMCQAnswered={this.props.onMCQAnswered}
                                    lectureId={this.props.lectureId}
                                    lessonId={this.props.lessonId}
                                    type={this.props.type}
                                    isAnswerExist={this.state.isAnswerExist}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    snackBar: msg => dispatch(snackBarActions.setErrorState(msg))
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(MCQ));
