import React, { Component } from 'react';
import  Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import * as actions from '../../actions/UserActions';


const style = {
    typography : {
        color : "white",
    }
}


class BasicUserInfo extends Component {
    

    componentDidMount(){
        this.props.getUserInfo();
    }

    render() { 
        const user = this.props.userInfo? this.props.userInfo : {name: '', email:''}
        const { classes } = this.props;
        return (  
            <div>
                <Typography className = {classes.typography} component="h4" variant="h1">
            {user.name}
          </Typography>
            </div>
        );
    }
}
 

const mapStateToProps = state => ({
    userInfo : state.users.userInfo,
})


const mapDispatchToProps = dispatch => ({
    getUserInfo : () => dispatch(actions.getUserInfo()),
})

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(style)(BasicUserInfo));