import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getRemarks } from './EvaluationErrors';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Overview from './Overview';
import Typography from '@material-ui/core/Typography';
import { CheckCircle } from '@material-ui/icons';

const styles = theme => ({
    paper: {
        height: '100%'
    },
    Button: {
        marginTop: '2vh'
    },
    failResult: {
        marginTop: '5vh',
        fontWeight: '800',
        color: 'red'
    },
    passResult: {
        marginTop: '15vh',
        fontWeight: '800',
        color: '#5eaa46'
    }
});
class EvaluationSummary extends Component {

    isCodeQualitySatisfactory(evaluation) {
        const violations = evaluation.violations;
        const violationKeys = Object.keys(violations);
        const totalViolations = violationKeys.map(violationKey =>
            violations[violationKey]).reduce((a, b) => a + b, 0);
        return totalViolations <= 5;
    }

    render() {
        const { classes } = this.props;
        const violations = this.props.evaluation.violations;
        const evaluation = this.props.evaluation;
        
        var result = '';
        var msgs = getRemarks(violations);
        if (this.isCodeQualitySatisfactory(this.props.evaluation)) {
            result += 'Your code quality is good!';
        } else {
            result += 'Your code needs some improvements';
        }
        return (
            <div>
                <center>
                    <h2>Code Analysis</h2>
                    {this.isCodeQualitySatisfactory(this.props.evaluation) ? (
                        <Typography variant="h3" className={classes.passResult}>
                            {result}
                            <CheckCircle />
                        </Typography>
                    ) : (
                        <Typography variant="h3" className={classes.failResult}>
                            {result}
                        </Typography>
                    )}
                </center>
                <Overview msgs={msgs} />
                <center>
                    <Link
                        to={{
                            pathname: `/report/${encodeURIComponent(JSON.stringify(evaluation))}`
                        }}
                        target="_blank">
                        <Button variant="contained" color="primary" className={classes.Button}>
                            View Details
                        </Button>
                    </Link>
                </center>
            </div>
        );
    }
}

export default withStyles(styles)(EvaluationSummary);
