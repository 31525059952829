import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Lock from '@material-ui/icons/Lock'
import SimpleModal from "../courses/SimpleModal";
import {subscribeToCourseRequest} from '../../actions/UserActions'
import {connect} from 'react-redux'

const styles = theme => ({
    lessonName: {
        margin: '0 10% 0 5%',
        fontFamily: 'Arial',
        color: 'Darkest indigo',
        padding: '1.4vh',
        fontWeight: '520',
        fontSize: '10pt',
        '@media(min-width:1200px)': {
            fontSize: '14pt',
        },
    },
    topicContainer: {
        display: 'flex',
        marginBottom: '2rem',
        position: 'relative',
    },
    sideMarker: {
        backgroundColor: '#083456',
        borderRadius: '8px 0 0 8px',
    }
})

class CourseNavElement extends Component {
    state = {
        open: false
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        let { classes, lesson, index } = this.props
        const blurClass = lesson.accessible ? "" : "blur"
        return (
            <>
                <Link to={this.props.path} key={lesson.id} style={{ textDecoration: 'none' }}>
                    <div className={classes.topicContainer}>
                        <div className={[this.props.topicClass, blurClass].join(' ')}>
                            <div className={classes.sideMarker}>&nbsp;&nbsp;</div>
                            <div className={classes.lessonName}><div style={{ fontSize: '8pt', marginBottom: '1.6rem' }}>Section {index + 1}</div><div>{lesson.name}</div></div>
                        </div>
                        {!lesson.accessible && <div className={"blurOverlay"} onClick={() => this.setState({open:true})}><Lock /></div>}
                    </div>
                </Link>
                <SimpleModal open={this.state.open} courseId={this.props.courseId} handleClose={this.handleClose} handleClick={() => this.props.subscribeToCourseRequest(this.props.courseId)}/>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    subscribeToCourseRequest : (courseId) => dispatch(subscribeToCourseRequest(courseId))
})

export default connect(() => ({}), mapDispatchToProps)(withStyles(styles)(CourseNavElement));
