import Constant from '../lectures/Evaluation/EvaluationConstants.json';

export function evaluate(report) {
    const violationSeverity = {};
    const evaluationParameters = Object.keys(Constant);
    for (var parameter in evaluationParameters) {
        violationSeverity[evaluationParameters[parameter]] = 0;
    }
    const fileAnalysis = report.fileAnalysis;
    for (var classes in fileAnalysis) {
        var classAnalysis = fileAnalysis[classes];
        var className = classAnalysis.className;
        delete classAnalysis.className;
        var methodAnalysis = classAnalysis.methodAnalysis;
        delete classAnalysis.methodAnalysis;
        for (var classProperties in classAnalysis) {
            violationSeverity[classProperties] +=  calculateViolationSeverity(classAnalysis[classProperties], Constant[classProperties]);
            evaluateProperty(classAnalysis, classProperties);
        }
        for (var method in methodAnalysis) {
            var methodProperties = methodAnalysis[method];
            for (var property in methodProperties) {
                violationSeverity[property] +=  calculateViolationSeverity(methodAnalysis[method][property], Constant[property]);
                evaluateProperty(methodProperties, property);
            }
        }
        classAnalysis.methodAnalysis = methodAnalysis;
        classAnalysis.className = className;
    }
    
    report['violations'] = violationSeverity;
    return report;
}

const calculateViolationSeverity = (value, threshold) => Math.max(Math.ceil((value - threshold) / threshold ), 0) 

function evaluateProperty(analysis, property) {
    var value = analysis[property];
    delete analysis[property];
    if (value > Constant[property]) {
        analysis[property] = { value: value, result: 'failed' };
    } else {
        analysis[property] = { value: value, result: 'passed' };
    }
}


