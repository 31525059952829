import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import * as actions from '../../actions/CourseActions'
import {connect} from 'react-redux'
import * as snackBarActions from '../../actions/SnackbarActions';
import LectureHeader from './LectureHeader';

const style = theme => ({
    root: {
        height:'100%',
    },
    Paper : {
        height:'100%',
    },
    rootContainer : {
        overflow : 'auto',
        height : '100%',
    },
    textarea : {
        backgroundColor: 'transparent',
        border : 'none',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        color: '#555',
        boxSizing: 'border-box',
        fontSize: '18px',
        left: '0%',
        padding: '0px 0px',
        position: 'relative',
        resize: 'none',
        width : '40vw',
        overflow : 'hidden',
        marginRight : '1em',
      
        '&:focus': {
          outline: 'none',
          color : '#111'    
        }
      },
      contentQuestion: {
        whiteSpace : 'pre-wrap',
        overflow : 'auto',
        marginBottom : "4vh",
        fontSize:'18px',
      },
      contentContainer : {
          padding :'5vh'
      },
      submitButton : {
        backgroundColor : theme.palette.primary.main,
        opacity : 0.9,
        color : '#fff',
        width : '8em',
        '&:hover' : {
            backgroundColor : 'rgba(8,92,186,1)',
        }
      },
      responseContainer : {
          display : 'flex',
          alignItems : 'flex-end',
      }
    })



class QuestionType extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            answer : this.props.answer
        };
        this.myRef = React.createRef();
    }

    componentDidMount(){
        this.resizeTextarea();
        if(!this.props.answer){
            if(this.props.currentAnswerSavedState){
                this.props.toggleAnswerSavedState();
            }
        }else{
            if(!this.props.currentAnswerSavedState){
                this.props.toggleAnswerSavedState();
            }
        }
    }

    resizeTextarea() {
        const textarea = this.myRef.current;
        textarea.style.height = 'inherit';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    componentWillReceiveProps(nextProps){
        if(this.props.answer !== nextProps.answer){
            this.setState({answer : nextProps.answer || ""})
        }   
    }

    componentDidUpdate(){
        this.resizeTextarea();
        if(!this.props.answer){
            if(this.props.currentAnswerSavedState){
                    this.props.toggleAnswerSavedState();
                }
        }else if(this.props.answer && this.props.answer === this.state.answer){
            if(!this.props.currentAnswerSavedState)
                    this.props.toggleAnswerSavedState();
        }
    }

    handleChange = (e) => {
        this.setState({answer : e.target.value});
        if(this.props.currentAnswerSavedState)
            this.props.toggleAnswerSavedState();
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`
    }

    submitAnswer = () => {
        if(this.state.answer && this.state.answer.length !== 0){
            this.props.postAnswer(this.props.lectureId, this.state.answer,this.props.type)
            .then(requestStatus => {
                if(requestStatus === 200){
                    this.props.snackBar("Your response has been recorded successfully!")
                    this.props.fetchAnswersAsync(this.props.lessonId);
                    if(!this.props.currentAnswerSavedState)
                    this.props.toggleAnswerSavedState();
                }
                else{
                    this.props.snackBar("Failed to save your response. Please try again later.!")
                }

            })
        }
        else{
            this.props.snackBar("Your response can't be empty. Please provide an answer!");
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Paper className={classes.Paper}>
                <div className = {[classes.rootContainer,"scrollbar"].join(' ')}>                
                    <LectureHeader />
                    <div className={classes.contentContainer}>
                        <div className={classes.contentQuestion} dangerouslySetInnerHTML={{__html:this.props.content}} />
                        <div className={classes.responseContainer}>
                            <textarea ref={this.myRef} className={classes.textarea} rows='1' onChange={this.handleChange} 
                            value={this.state.answer} placeholder="Please Enter Text"></textarea>
                            <Button variant='outlined' className={classes.submitButton} onClick={this.submitAnswer}
                            >Save</Button>
                        </div>
                    </div>
                </div>
                </Paper>
            <br/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    postAnswer : (lectureId, answer,type) => dispatch(actions.postAnswerAsync(lectureId, answer,type)),
    fetchAnswersAsync: (lessonId) => dispatch(actions.fetchAnswersAsync(lessonId)),
    snackBar : (msg) => dispatch(snackBarActions.setErrorState(msg))
})

const mapStateToProps = (state) => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(QuestionType));