import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles'
import LandingPageHeader from './LandingPageHeader';
import { Button } from '@material-ui/core';


const styles = theme => ({
    dash:{
        height:'1000px',
        backgroundImage:'url(/static/images/background.svg)',
        '@media(min-width:1920px)':{
            backgroundImage:'linear-gradient(to right,#3494E6fe,#EC6EADfe)'  
        },
        backgroundRepeat:'no-repeat',
        fontFamily: 'Josefin Sans',
    },
    dashContent:{
        display:'flex',
        justifyContent:'center',
        padding:'7% 0%',
        '@media(max-width:900px)':{
            flexDirection:'column'
        },
    },
    companyDescContainer:{
        display:'flex',
        lineHeight:'1.6',
        color:'#fff',
        flexDirection:'column',
        justifyContent:'space-between',
        '@media(max-width:1300px)':{
            marginRight:'0px',
            width:'60vw'
        },
        '@media(max-width:900px)':{
            width:'100vw',
            textAlign:'center'
        },
        marginRight:'60px'
    },
    title:{
        '@media(max-width:900px)':{
            textAlign:'center'
        },
        '@media(max-width:400px)':{
            fontSize:'40px',            
        },
        fontSize:'55px',
        color:'#fff',
        marginBottom:'0.4em',
        lineHeight:'1.2'
    },
    exploreButton:{
        color:'#fff',
        width:'300px',
        borderRadius:'24px',
        backgroundColor:'#7bc97e',
        padding:'0.5em 0',
        '&:hover':{
            backgroundColor:'#7bc97e'
        }
    },
    linkContainer:{
        marginTop:'5em',
    },
    quote:{
        fontSize:'20px',
    },
    signUpContainer:{
        zIndex:'999',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        '@media(max-width:900px)':{
            margin:'2em auto',
        },
        '@media(max-width:600px)':{
            width:'auto',
            height:'auto',
        },

    }
})

class Dash extends Component {
    render() {
        const {classes} = this.props
        return (
            <div className={classes.dash}>
                <LandingPageHeader />
                <div className={classes.dashContent}>
                    <div className={[classes.companyDescContainer,"translateAndFadeIn"].join(' ')}>
                        <p className={classes.title}><b>ENHANCED ENGINEERING</b></p>
                        <div><q><b className={classes.quote}>The best way to understand any technology is to build something.</b></q></div>
                        We are a team of technologists that help you build products, and evolve the technology, practices, and skills of the team.<br/>
                        We follow our own brand of Continuous Delivery and Extreme Programming, and upskill your team as we build products.<br/>
                        We have expertise in web development, mobile, and devops. <br/>
                        <span className={classes.linkContainer}><a href="#consulting"><Button variant="outlined" className={classes.exploreButton} color="default"><b>Explore</b></Button></a></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Dash);