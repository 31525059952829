import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';

const styles = theme => ({
  container: {
      overflow: 'auto',
      height: '100%'
  },
  urlContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  },
  subContainer: {
      marginTop: '3vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '1.5em'
  },
  buttonContainer: {
      marginTop: '8vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  },
  fieldSeparator: {
      fontWeight: '900',
      marginTop: '3vh'
  },
  radioButton: {
      marginTop: '1.5vh'
  },
  description: {
      marginTop:'3vh'
  }
});

const CodeAnalysisInput = (props) => {

  const [gitUrl, setGitUrl] = React.useState("");
  const [revisionType, setRevisionType] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [commitId, setCommitId] = React.useState("");

  const { classes } = props;

  const handleGitUrlChange = (e) => {
    setGitUrl(e.target.value);
  };

  const handleCommitIdChange = (e) => {
    setCommitId(e.target.value);
  };

  const handleBranchChange = (e) => {
    setBranch(e.target.value);
  };

  const handleRevisionTypeChange = (e) => {
      setRevisionType(e.target.value)
  }

  const handleCodeAnalysisSubmission = (e) => {
    props.submitCodeForAnalysis(gitUrl, branch, commitId)
  }

    return (
        <div className={[classes.container, 'scrollbar'].join(' ')}>

            <center>
                <h2>Code Analysis</h2>
                <h4 className={classes.description}>
                    <i>
                    Input your repo url, and either branch or commit SHA to analyse how clean your code is.
                    </i>
                </h4>
                <h4>
                    <i>
                    Code quality metrics are calculated based on complexity and length of classes and methods.
                    </i>
                </h4>
            </center>

            <div className={classes.urlContainer}>
                <TextField id="url" label="git url" variant="standard" value={gitUrl} onChange={handleGitUrlChange} />
            </div>
            <div className={classes.subContainer}>
                <div>
                    <Radio
                        color="primary"
                        className={classes.radioButton}
                        value="branch"
                        checked={revisionType === 'branch'}
                        onChange={handleRevisionTypeChange}
                    />
                    <TextField
                        id="branch"
                        label="branch Name"
                        variant="standard"
                        value={branch}
                        onChange={handleBranchChange}
                        disabled={revisionType !== 'branch'}
                    />
                </div>
                <Typography className={classes.fieldSeparator} variant="h4">
                    OR
                </Typography>
                <div>
                    <Radio
                        color="primary"
                        className={classes.radioButton}
                        value="commitId"
                        checked={revisionType === 'commitId'}
                        onChange={handleRevisionTypeChange}
                    />
                    <TextField
                        id="commitId"
                        label="commit Id"
                        variant="standard"
                        value={commitId}
                        onChange={handleCommitIdChange}
                        disabled={revisionType !== 'commitId'}
                    />
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={handleCodeAnalysisSubmission} data-testid="getReport">
                    Get Report
                </Button>
            </div>
        </div>

    )
}

export default withStyles(styles)(CodeAnalysisInput)
