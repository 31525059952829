import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux'
import * as actions from '../actions/UserActions'
import { Button, Paper } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import '../App.css'
import {Link} from 'react-router-dom'
import SuccessIcon from '@material-ui/icons/CheckCircleOutline'
import * as snackBarActions from '../actions/SnackbarActions';

const style = theme => ({
    root:{
        minHeight:'100vh',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundImage:'linear-gradient(to right,#3494E6,#EC6EAD)',
        color:`${theme.palette.primary.main}`
    },
    emailInput:{
        marginBottom:'2em',
        height:'2.8em',
        border:'none',
        fontSize:'14px',
        borderBottom:`2px solid #000`,
        '&:focus': {
            outline: 'none',
            color : '#111'    
        }
    },
    contentPaper:{
        borderRadius:'8px',
        height:'600px',
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 5}px ${theme
        .spacing.unit * 10}px`
    },
    loginLink:{
        textAlign:'center'
    },
    header:{
        margin:'0 0 2em 0',
        fontSize:'34px',
        textAlign:'center'
    },
    sendButton:{
        backgroundColor:`${theme.palette.primary.main}`,
        color:'#fff',
        margin:'0 0 7em 0',
        height:'40px',
        borderRadius:'25px',
    },
    successIcon:{
        color:'green',
        height:'3em',
        width:'3em',
        margin:'0 0 1em 0',
    },
    successIconContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    resetPasswordContainer:{
        maxWidth:'370px',
        height:'100%',
        margin:'0 auto',
      }
})   
 
class SendPasswordResetLink extends Component {
    state = {
        email : "",
        emailSent: false
    }

    handleChangeEmail = e => this.setState({email:e.target.value})

    handleSubmit = e => {
        e.preventDefault()
        if(this.state.email.length === 0){
            this.props.snackBar("Please enter your registered email address.")
            return;
        }
        this.props.passwordResetRequest(this.state.email).then(res =>  this.setState({emailSent:res}))
    }

    render() {
        const {classes} = this.props;
        let header = this.state.emailSent ? "Password reset link sent":"Reset"
        let rotateClass = this.state.emailSent ? "rotate": "";
        return (
            <div className={classes.root}>
                <div className={classes.resetPasswordContainer}>
                    <Paper className={[classes.contentPaper,rotateClass].join(' ')}>
                    {this.state.emailSent && <div className={classes.successIconContainer}><SuccessIcon className={[classes.successIcon,rotateClass].join(' ')}/></div>}
                            <h1 className={[classes.header,rotateClass].join(' ')}>{header}</h1>
                            {!this.state.emailSent ?
                                <Fragment>
                                    <div>
                                        <p className={classes.resetInstructions}>Enter the <b>email</b> address you used to register with us. 
                                        We will send you an email to with the link to reset your password.</p>
                                    </div>
                                    <input type="email" placeholder="Email" className={classes.emailInput} onChange={this.handleChangeEmail}/>
                                    <Button type="submit" variant="contained" color="primary" onClick={this.handleSubmit} className={classes.sendButton}><b>Send</b></Button>
                                    <p className={classes.loginLink}>Back to <Link to="/login">login</Link></p>
                                </Fragment> :
                                <Fragment>
                                    <p className={rotateClass}>Please check your mail for further instructions.</p>
                                    <p className={rotateClass}>Once you reset your password you can <Link to="/login">sign in</Link></p>
                                </Fragment>
                            }
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    passwordResetRequest : (email) => dispatch(actions.passwordResetRequest(email)),
    snackBar    : (msg) => dispatch(snackBarActions.setErrorState(msg))
})

export default connect(() => ({}),mapDispatchToProps)(withStyles(style)(SendPasswordResetLink));


