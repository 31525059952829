import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import DiscoverCourseCard from './DiscoverCourseCard';
import { connect } from 'react-redux'

const styles = theme => ({
    root: {
        marginTop: '4em'
    },
    sectionTitle: {
        marginBottom: '1em'
    },
    wrapper: {
        display: 'flex',
        flexWrap:'wrap',
        flexGrow:'1',
        '& button': {
            marginRight: '5em',
            marginBottom:'5em'
        }
    }
})

class DiscoverSection extends Component {
    render() {
        const { classes } = this.props
        const courses = this.props.courses ? this.props.courses : []
        return (
            courses.length>0 && <div className={classes.root}>
                <h3 className={classes.sectionTitle}>Discover Courses</h3>
                <div className={classes.wrapper}>
                    {courses.map(course => {
                        const path = "/courses/" + course.id
                        return <DiscoverCourseCard key={course.id} name={course.name} desc={course.description} path={path} id={course.id} history={this.props.history}/>
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    courses: state.courses.discoverCourses ? state.courses.discoverCourses.courses : undefined
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DiscoverSection));