import React, { Component, Fragment } from 'react';
import { Button, Paper } from '@material-ui/core';
import queryString from 'query-string'
import {connect} from 'react-redux'
import * as actions from  '../actions/UserActions'
import {withStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import '../App.css'

const style = theme => ({
    root:{
        minHeight:'100vh',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundImage:'linear-gradient(to right,#3494E6,#EC6EAD)',
        color:`${theme.palette.primary.main}`
    },
    contentPaper:{
        height:'600px',
        display:'flex',
        borderRadius:'8px',
        flexDirection:'column',
        alignItems: "stretch",
        padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 5}px ${theme
        .spacing.unit * 10}px`
    },
    submitButton:{
        backgroundColor:`${theme.palette.primary.main}`,
        color:'#fff',
        margin:'0 0 3em 0',
        borderRadius:'25px',
    },
    fields:{
        margin:'0 0 10% 0',
        height:'2em',
        border:'none',
        borderBottom:`1px solid #888`,
        '&:focus': {
            outline: 'none',
            color : '#111',
            borderBottom:`2px solid #000`,
        }
    },
    header:{
        margin:'0 0 2em 0',
        fontSize:'34px',
        textAlign:'center'
    },
    loginLink:{
        textAlign:'center'
    },
    errormsg:{
        color:'#f00',
        textAlign:'center',
        margin:'0 0 1em 0'
    },
    resetPasswordWrapper:{
        minWidth:'370px',
        height:'100%',
        margin:'0 auto',
    }
})

class ForgotPassword extends Component {
    state = {
        password : "",
        retypedPassword : "",
        errormsg : "",
        token :"",
        showErrorMsg : false,
        passwordChanged: false
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        this.setState({token:query.reset_password_token})
    }

    handleResetPassword = e => {
        e.preventDefault();
        if(this.state.password !== this.state.retypedPassword)  this.setState({errormsg:"The passwords don't match each other.",showErrorMsg:true})
        else if(this.state.password.length<6)   this.setState({errormsg:"The password should have more than 5 characters.",showErrorMsg:true})
        else{
            this.setState({errormsg:"",showErrorMsg:false})
            this.props.resetPassword(this.state.password,this.state.token).then(res => {
                this.setState({passwordChanged:res})
                if(!res)    this.setState({errormsg:"Password reset failed",showErrorMsg:true})
            })
        }
    }

    handleChangePassword = (e) => {
        this.setState({password : e.target.value})
    }

    handleChangeRetypedPassword = (e) => {
        this.setState({retypedPassword : e.target.value})
    }

    render() {
        const {classes} = this.props;
        let header = !this.state.passwordChanged ? "New Password": "You have successfully changed your password."
        let rotateClass = this.state.passwordChanged ? "rotate": "";
        return (
            <div className={classes.root}>
                <div className={classes.resetPasswordWrapper}>
                    <Paper className={[classes.contentPaper,rotateClass].join(' ')}>
                        <h1 className={[classes.header,rotateClass].join(' ')}>{header}</h1>     
                        {!this.state.passwordChanged ? <Fragment>
                            <input type="password" placeholder="Password" autoFocus={true} className={classes.fields} onChange={this.handleChangePassword}/>
                            <input type="password" placeholder="Confirm password" className={classes.fields} onChange={this.handleChangeRetypedPassword}/>
                            {this.state.showErrorMsg && <div className={classes.errormsg}>{this.state.errormsg}</div>}
                            <Button type="submit" variant="contained" color="primary" className={classes.submitButton} onClick={this.handleResetPassword}>Submit</Button>
                            <p className={classes.loginLink}>Go to <Link to="/login">login</Link></p>                            
                        </Fragment> :
                        <Fragment>
                            <p className={[classes.loginLink,rotateClass].join(' ')}>You can <Link to="/login">login</Link> here</p>
                        </Fragment>}
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    resetPassword : (password,token) => dispatch(actions.resetPassword(password,token))
})

export default connect(()=> ({}),mapDispatchToProps)(withStyles(style)(ForgotPassword));