import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
    bootcampsContainer:{
        display:'flex',
        alignItems:'center',
        textAlign: 'left',
        justifyContent:'flex-start',
        backgroundColor: '#eee',
        width:'100vw',
        '@media(max-width:1200px)':{
            flexDirection:'column-reverse'
        },
    },
    bootcampDesc:{
        width:'50%',
        padding:'0 2em',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        color:'#666',
        '@media(max-width:1200px)':{
            width:'100%',
            marginTop:'3em'
        },
    }
})

const imageNames = ['4.jpeg','5.jpeg','6.jpeg','7.jpeg','8.jpeg','9.jpeg','10.jpeg']

class BootcampsSlideShow extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.bootcampsContainer} id="bootcamps&trainings">
                <div className={classes.bootcampDesc}>
                    <p className="centerTitle">BOOTCAMPS AND TRAININGS</p>
                    <p>Our bootcamps and trainings involve <b>hands-on</b> problem solving, where participants understand application and the underlying theory.
                    Our trainers have <b>trained people from over 12 countries</b>. We train <b>employees, students, and also conduct train-the-trainer programs</b>.
                    <br/><br/>The trainer spends most of the time in <b>two-way discussions</b>, and helping the participants with code.</p>
                </div>
                <div className="frame">
                    <div className="flexImages">
                        {imageNames.map((imageName, index) =>
                            <div key={index}className="container">
                                <img src={`/static/images/${imageName}`} alt="bootcampImages"/>
                            </div>)}
                    </div>  
                </div>  
            </div>
        );
    }
}

export default withStyles(styles)(BootcampsSlideShow);