import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import DetailCourseDescModal from './DetailCourseDescModal'

const styles = {
  card: {
    width: 360,
    marginTop: '1em',
    marginRight: '5em',
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '3px 3px 13px #aaa',
      cursor:'pointer'
    }
  },
  media: {
    backgroundImage: 'linear-gradient(#e96443,#904e95)',
    color: '#fff',
    fontSize: '22px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '180px'
  },
  courseDesc: {
    color: '#666',
    margin: '0',
    display: 'flex',
    padding: '1.5em',
    justifyContent: 'center',
    alignItems: 'center',
    height: '250px',
  },
  CardContent: {

  },
  htmlButton: {
    border: 'none',
    backgroundColor: '#0000',
    color: 'blue'
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
};

class CourseCard extends Component {
  state = {
    open: false,
  };

  allowedStringLength = 400

  componentDidMount() {
    if (this.props.courseDescription.length >= this.allowedStringLength)
      document.getElementById(`readMoreButton${this.props.id}`).addEventListener('click', this.handleOpen)
  }

  componentWillUnmount() {
    if (this.props.courseDescription.length >= this.allowedStringLength)
      document.getElementById(`readMoreButton${this.props.id}`).removeEventListener('click', this.handleOpen)
  }

  handleOpen = e => {
    e.stopPropagation()
    this.setState({ open: true })
  }

  handleClose = () => this.setState({ open: false });

  render() {
    const { classes } = this.props;
    const readMore = this.props.courseDescription.length < this.allowedStringLength ? "" : `...<button class=${classes.htmlButton} id=readMoreButton${this.props.id}>read more</button>`
    return (
      <>
        <Card onClick={() => this.props.history.push(this.props.coursePath || "/")} className={classes.card}>
          <div className={classes.media}>
            {this.props.courseName}
          </div>
          <div className={[classes.courseDesc, "resetMarginsAndPadding"].join(' ')} dangerouslySetInnerHTML={{ __html: `${this.props.courseDescription.substring(0, this.allowedStringLength)} ${readMore}` }} />
        </Card>
        <DetailCourseDescModal open={this.state.open} handleClose={this.handleClose} handleOpen={this.handleOpen} title={this.props.courseName} desc={this.props.courseDescription} />
      </>
    );
  }
}

CourseCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CourseCard);
