import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/ActionTypes';
import * as actions from '../../actions/CodeAnalysisActions';
import { evaluate } from '../code-analysis/code-analysis.functions';
import CodeAnalysis from '../code-analysis/CodeAnalysis';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({

  paper: {
      height: '80vh',
      width: '80vw',
  },
  root: {
    display:'flex',
    padding: '0 10vh',
    width: '100%',
    flex: 1,
    height: '100vh',
    justifyContent:'center',
    alignItems:'center',
}

});

const CodeAnalysisPage = (props) => {

  const { classes } = props;

  const submitCodeForAnalysis = (gitUrl, branch, commitId) => {
    if(gitUrl && (branch || commitId)) {
        props.submitCodeForAnalysis(gitUrl, branch, commitId)
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} data-testid="mainScreen">
        <CodeAnalysis 
        requestStatus={props.requestStatus}
        formattedReport={props.formattedReport}
        history={props.history}
        submitCodeForAnalysis={submitCodeForAnalysis}
        />
      </Paper>
    </div>
  )

}


const mapDispatchToProps = dispatch => ({
  submitCodeForAnalysis : (gitUrl, branchName, commitId) => dispatch(actions.submitCodeForAnalysis(gitUrl, branchName, commitId))
})

const mapStateToProps = (state, ownProps) => {
  const analysisReport = state.codeAnalysis.report
  const status = (analysisReport || {}).status || actionTypes.STATE_UNSUBMITTED
  const formattedReport = (status === actionTypes.STATE_SUCCESS)?
                            evaluate(analysisReport.data): undefined
  return {
    requestStatus: status,
    formattedReport: formattedReport
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CodeAnalysisPage)) 
