import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core/styles';
import LectureHeader from './LectureHeader';


const styles = theme => ({
    paper : {
        height:'100%'
    },
    container : {
        overflow : 'auto',
        height : '100%',
    },
    content : {
        margin : "5vh",
        whiteSpace : 'pre-wrap',
        fontSize:'18px',
    },
})

class DocumentType extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.paper}>
                <div className = {[classes.container,"scrollbar"].join(' ')}>
                    <LectureHeader />
                    <div className={[classes.content,"code"].join(' ')} dangerouslySetInnerHTML = {{__html : this.props.content}} />
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(DocumentType);