import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import CourseNavElement from './CourseNavElement';

const styles = theme => ({
    topics: {
        fontSize: '10pt',
        borderRadius: '8px',
        '@media(min-width:1200px)': {
            fontSize: '14pt',
        },
        display: 'flex',
        width: '100%',
        boxShadow: '2px 6px 4px -1px #ccc',
        transition: '400ms',
        '&:hover': {
            backgroundColor: '#fff',
            transform: 'translate3d(20px,0px,0px)',
            boxShadow: '2px 6px 4px -1px #ccc',
        }

    },
    root: {
        padding: '2rem',
        borderRadius: '8px',
    },
    currentTopic: {
        fontSize: '10pt',
        borderRadius: '8px',
        '@media(min-width:1200px)': {
            fontSize: '14pt',
        },
        display: 'flex',
        boxShadow: '2px 6px 4px -1px #ccc',
        backgroundColor: '#60d9fb',
        width: '100%',
    },
})

class CourseNav extends Component {
    render() {
        const { classes } = this.props;
        const lessonList = this.props.lessons.map((lesson, index) => {
            let currentTopic = parseInt(this.props.lessonId) === lesson.id ? classes.currentTopic : classes.topics;
            let path = lesson.accessible ? `/courses/${this.props.courseId}/lessons/${lesson.id}` : {}
            return (
                <CourseNavElement topicClass={currentTopic} path={path} lesson={lesson} index={index} key={lesson.id} courseId={this.props.courseId}/>
            )
        })
        return (
            <div className={classes.root}>
                {lessonList}
            </div>
        );
    }
}

export default withStyles(styles)(CourseNav);