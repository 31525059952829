import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import * as actions from "../../actions/CourseActions";
import { connect } from "react-redux";
import * as snackBarActions from "../../actions/SnackbarActions";

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: "14px",
    flexBasis: "100%",
    marginBottom: "1em",
  },
  optionText: {
    border: "1px solid #ccc",
    borderLeft: "none",
    padding: "1em",
    width: "100%",
    textAlign: "left",
  },
  button: {
    padding: "0",
    width: "100%",
    margin: "0",
    borderRadius: "0",
    justifyContent: "left",
    textTransform: "none",
  },
});

class Option extends Component {
  state = {
    clicked: false,
    correctAnswer: "",
  };

  myRef = React.createRef();

  handleClick = () => {
    if (!this.props.answer) {
      this.setState({ clicked: true });
      this.props
        .postAnswer(
          this.props.lectureId,
          "option",
          this.props.type,
          this.props.id
        )
        .then((requestStatus) => {
          if (requestStatus === 200) {
            this.props.handleSubmit(this.props.id);
            this.props.fetchAnswersAsync(this.props.lessonId);
          } else {
            this.props.snackBar(
              "Failed to save your response. Please try again later.!"
            );
          }
        });
    } else {
      this.props.snackBar("You already attempted this question");
    }
  };
  componentDidUpdate() {
    let color;
    if ((this.props.correctAnswer !== "") & this.state.clicked) {
      if (this.props.correctAnswer === this.props.id) {
        this.props.onMCQAnswered(true);
      } else {
        this.props.onMCQAnswered(false);
      }
      color =
        this.props.correctAnswer === this.props.id
          ? "rgba(50,205,50,0.3)"
          : "rgba(255,0,0,0.3)";
    }
    if (this.props.correctAnswer === this.props.id) {
      color = "rgba(50,205,50,0.3)";
    }
    if (this.props.answer === this.props.id) {
      color =
        this.props.correctAnswer === this.props.id
          ? "rgba(50,205,50,0.3)"
          : "rgba(255,0,0,0.3)";
    }
    this.myRef.current.style.border = `1px solid ${color}`;
    this.myRef.current.style.borderLeft = `none`;
    this.myRef.current.style.backgroundColor = `${color}`;
  }

  render() {
    const { classes } = this.props;
    const leftBorder = { borderLeft: `${this.props.color} 10px solid` };
    return (
      <div className={classes.root} style={leftBorder}>
        <Button className={classes.button} onClick={this.handleClick}>
          <div
            className={classes.optionText}
            ref={this.myRef}
            dangerouslySetInnerHTML={{ __html: this.props.option }}
          />
        </Button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  postAnswer: (lectureId, answer, type, OptionSelected) =>
    dispatch(actions.postAnswerAsync(lectureId, answer, type, OptionSelected)),
  fetchAnswersAsync: (lessonId) =>
    dispatch(actions.fetchAnswersAsync(lessonId)),
  snackBar: (msg) => dispatch(snackBarActions.setErrorState(msg)),
});

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Option));
