import React, { Component } from 'react';
import { withStyles, Button } from '@material-ui/core';
import LandingPageHeader from '../LandingPage/LandingPageHeader';
import LessonCardOverview from './LessonCardOverview';
import Footer from '../LandingPage/Footer';
import {Link} from 'react-router-dom'
import * as actions from '../../actions/CourseActions'
import {connect} from 'react-redux'
import * as types from '../../actions/ActionTypes'
import NotFound from '../../pages/NotFound404Page'

const styles = theme => ({
    headerbackground : {
        backgroundImage:'linear-gradient(to right,#3494E6fe,#EC6EADfe)' ,
        color:'#fff',
        fontFamily: 'Open Sans, sans-serif',
        padding:'0',
        paddingBottom:'4em',
    },
    courseIntro:{
        padding:'2em 15em 2em 24em',
        '@media(max-width:720px)':{
            padding:'0.5em',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        '@media(min-width:720px) and (max-width:1490px)':{
            padding:'8em',
        },
    },
    courseName:{
        fontSize:'45px',
        fontWeight:'100',
        marginBottom:'1em',
        '@media(max-width:720px)':{
            textAlign:'center'
        },
    },
    syllabusHeader:{
        fontSize:'45px',
        color:'#be6837',
	    textTransform: 'uppercase',
        fontWeight:'300',
    },
    syllabus:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        marginTop:'4em',
        padding:'2em 13em',
        '@media(max-width:1490px)':{
            padding:'0.5em'
        },
        '@media(max-width:350px)':{
            padding:'0em'
        },
    },
    startCourseButton:{
        color:'#fff',
        width:'300px',
        borderRadius:'24px',
        backgroundColor:'#7bc97e',
        marginTop:'3em',
        padding:'0.5em 0',
        '&:hover':{
            backgroundColor:'#7bc97e'
        }
    },
})

const filter = (lectures, type) => lectures.filter(lecture => lecture.type === type)

class CourseOverview extends Component{
    componentDidMount(){
        window.scrollTo({top:0,behavior:'auto'})
        this.props.courseOverview(this.props.match.params.courseName);
    }
    render(){
        const {classes} = this.props;
        let lectures,courseDesc,courseName;
        if(!this.props.courseOverviewAndDesc){
            return null;
        }
        if(this.props.courseOverviewAndDesc.status === types.STATE_SUCCESS){
            courseName= this.props.courseOverviewAndDesc.overview.name
            courseDesc = this.props.courseOverviewAndDesc.overview.description
            lectures = this.props.courseOverviewAndDesc.overview.lessonDTOS.map((lessonDTO,index) => {
                return <LessonCardOverview key={index+1} lessonNo={index+1} lessonName={lessonDTO.name} lessonDesc={lessonDTO.desc} 
                readings={filter(lessonDTO.lecturesDTOS,"DOCUMENT")} questions={filter(lessonDTO.lecturesDTOS,"QUESTION")} 
                videos={filter(lessonDTO.lecturesDTOS,"VIDEO")}/>
            })
        }
        else return <NotFound />
        return(
            <>
                <div className={classes.headerbackground}>
                    <header>
                        <LandingPageHeader />
                    </header>
                            <div className={classes.courseIntro}>
                                <p className={classes.courseName}>{courseName}</p>
                                <div dangerouslySetInnerHTML={{__html: courseDesc}}/>
                                <Link to={"/login"}><Button variant="outlined" className={classes.startCourseButton} color="default"><b>START COURSE</b></Button></Link>
                            </div>
                    </div>
                <div className={classes.syllabus}>
                    <div>
                        <p className={classes.syllabusHeader}>Things to learn from this course</p>
                    </div>
                    {lectures}
                </div>
                <Footer />            
            </>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    courseOverviewAndDesc : state.courses.courseOverview ? state.courses.courseOverview[ownProps.match.params.courseName] : undefined
})

const mapDispatchToProps = dispatch => ({
    courseOverview : courseName => dispatch(actions.courseOverview(courseName))
}) 

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CourseOverview))