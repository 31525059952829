import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/ActionTypes';
import * as actions from '../../actions/LectureCodeEvaluationActions';
import { evaluate } from '../code-analysis/code-analysis.functions';
import CodeAnalysis from '../code-analysis/CodeAnalysis';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LectureHeader from '../lectures/LectureHeader';

const styles = theme => ({
    paper: {
        height: '100%'
    }
  });
  
const CodeEvaluationLecture = (props) =>  {

    const { classes } = props;

    const submitCodeForAnalysis = (gitUrl, branch, commitId) => {
        if(gitUrl && (branch || commitId)) {
            props.evaluateLectureCodeQuality(
                props.lectureId, 
                gitUrl, 
                branch,
                commitId)
        }
    }

    return (
        <Paper className={classes.paper} data-testid="mainScreen">
            <LectureHeader />
            <CodeAnalysis
                requestStatus={props.requestStatus}
                formattedReport={props.formattedReport}
                history={props.history}
                submitCodeForAnalysis={submitCodeForAnalysis}
            />
        </Paper>
    );
}

const mapDispatchToProps = dispatch => ({
    evaluateLectureCodeQuality : (lectureId, gitUrl, branchName, commitId) => dispatch(actions.evaluateLectureCodeQuality(lectureId, gitUrl, branchName, commitId)),
})

const mapStateToProps = (state, ownProps) => {
    const lectureId = ownProps.lectureId
    const evaluationReportForLecture = state.lectureCodeEvaluation.evaluationReport[lectureId]
    const status = (evaluationReportForLecture || {}).status || actionTypes.STATE_UNSUBMITTED
    const formattedReport = (status === actionTypes.STATE_SUCCESS) ? 
                            evaluate(evaluationReportForLecture.data) : undefined

    return {
            requestStatus: status,
            formattedReport: formattedReport
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CodeEvaluationLecture));
